import Highcharts from 'highcharts';
import { Color } from '@nkr-online/interfaces';

export class ChartExportService {
  public getChartLang(appTranslations: any): Highcharts.LangOptions {
    return { downloadPNG: appTranslations.services.chart.export.png };
  }

  public getChartExporting(
    pageTitle: string
    // chartOptions: Highcharts.Options
  ): Highcharts.ExportingOptions {
    const exportWidth = 640;
    const exportHeight = 520;
    const scale = 1.5;
    const originalImageWidth = 199 / scale;
    const originalImageHeight = 53 / scale;
    return {
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG'],
        },
      },
      sourceWidth: exportWidth,
      sourceHeight: exportHeight,
      allowHTML: false,
      chartOptions: {
        chart: {
          events: {
            // Highcharts does not support fat arrow notation
            // tslint:disable-next-line
            load: function () {
              // TODO add reference to iknl logo other than performation domain
              this.renderer
                .image(
                  `${location.protocol}//${window.location.host}/assets/iknl-logo.png`,
                  exportWidth - originalImageWidth,
                  0,
                  originalImageWidth,
                  originalImageHeight
                )
                .add();
            },
          },
          style: {
            fontFamily: `'Avenir LT Std', sans-serif`,
            fontWeight: '500',
          },
        },
        xAxis: {
          labels: {
            // HACK added formatter for long labels over 40 characters
            // Highcharts does not support fat arrow notation
            // tslint:disable-next-line
            formatter: function () {
              const valueString = this.value.toString();
              if (valueString.length < 40) {
                return valueString;
              } else {
                const maxLabelLength = 40;
                return valueString.substring(0, maxLabelLength).concat('...');
              }
            },
            rotation: 0,
          },
        },
        legend: {
          enabled: true,
        },
        title: {
          text: pageTitle,
          widthAdjust: originalImageWidth * -1,
          align: 'left',
          style: {
            fontSize: '20px',
            fontWeight: '600',
            color: Color.DarkBlue,
          },
        },
        // subtitle: {
        //     text:
        //         chartOptions &&
        //         chartOptions.title &&
        //         chartOptions.title.text
        //             ? chartOptions.title.text
        //             : '',
        //     widthAdjust: originalImageWidth * -1,
        //     align: 'left',
        // },
      },
      fallbackToExportServer: false,
    };
  }
}
