import { Injectable } from '@angular/core';
import {
  ISelectionFilter,
  ISelectionFilterCollection,
  IFilterValueItem,
} from '@nkr-online/repo';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public getSelectedFilterValue(
    filterName: string,
    filtersList: ISelectionFilterCollection
  ): IFilterValueItem {
    const currentSelectionFilter = filtersList.find(
      (filter: ISelectionFilter) => filter.filterName === filterName
    );
    const selectedItem: IFilterValueItem =
      currentSelectionFilter &&
      currentSelectionFilter.selectedFilterValues.length
        ? currentSelectionFilter.selectedFilterValues[0]
        : undefined;

    if (selectedItem) {
      const currentPossibleFilterValue =
        currentSelectionFilter.possibleFilterValues.find(
          (filterValue: IFilterValueItem) =>
            filterValue.filterValueName === selectedItem.filterValueName
        );
      return currentPossibleFilterValue;
    } else {
      return undefined;
    }
  }
}
