import { ISerieVisibility } from '@nkr-online/interfaces';
import { Subject } from 'rxjs';

export class ChartEventsService {
  public getChartEventsValues(
    serieVisibilitySubject: Subject<ISerieVisibility>
  ): Highcharts.SeriesEventsOptionsObject {
    return {
      // Highcharts does not support fat arrow notation
      // tslint:disable-next-line
      legendItemClick: function () {
        const serieName = this.name;
        const visibility = !this.visible;

        serieVisibilitySubject.next({
          serieName,
          visibility,
        });
        return;
      },
    };
  }
}
