import {
  Component,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  ISelectionFilterTemplate,
  ISelectionFilterConfigCollection,
  ISelectionFilterConfig,
} from '@nkr-online/interfaces';
import { sortBy } from 'lodash';
import { ISelectionFilterCollection, ISelectionFilter } from '@nkr-online/repo';

@Component({
  selector: 'iknl-filters-menu',
  templateUrl: './filters-menu.component.html',
  styleUrls: ['./filters-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionFilterMenuComponent implements OnChanges {
  @Input() public layout: 'horizontal' | 'vertical';
  @Input() public defaultSerieSelectionFilter: string;
  @Input() private filterConfig: ISelectionFilterConfigCollection;
  @Input() private filters: ISelectionFilterCollection;
  @Output() public modalTabChange: EventEmitter<any> = new EventEmitter();
  @Output() private filtersChanged: EventEmitter<ISelectionFilter> =
    new EventEmitter();

  public filtersList: ISelectionFilterTemplate[];

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && this.filters && this.filters.length) {
      this.filtersListUpdatedHandler();
    }
  }

  public modalTabChangeHandler(value: any) {
    this.modalTabChange.emit(value);
  }

  private filtersListUpdatedHandler() {
    const filteredMenuItems: ISelectionFilter[] =
      this.filterSelectionFilterItems(this.filters);
    const formattedSelectionFilterMenuItems: ISelectionFilterTemplate[] =
      this.formatSelectionFilterItems(filteredMenuItems);
    const sortedMenuItems: ISelectionFilterTemplate[] = sortBy(
      formattedSelectionFilterMenuItems,
      ['template.orderNr']
    );

    this.filtersList = sortedMenuItems;
  }

  public filterValueChangeHandler(newValue: ISelectionFilter) {
    this.filtersChanged.emit(newValue);
  }

  private filterSelectionFilterItems(
    filterItems: ISelectionFilter[]
  ): ISelectionFilter[] {
    return filterItems
      .filter((filterItem: ISelectionFilter) => {
        const isSeriesSelectionFilter: boolean =
          this.defaultSerieSelectionFilter === filterItem.filterName;
        return !isSeriesSelectionFilter;
      })
      .filter((filterItem: ISelectionFilter) => {
        const allFilterMenuUris = this.filterConfig.map(
          (config) => config.filterName
        );
        return allFilterMenuUris.indexOf(filterItem.filterName) !== -1;
      });
  }

  private getSelectionFilterTemplateMeta(
    currentFilterName: string
  ): ISelectionFilterConfig {
    let filterMenuItemConfig: ISelectionFilterConfig;
    filterMenuItemConfig = this.filterConfig.find(
      (fmConfig) => fmConfig.filterName === currentFilterName
    );
    const noGutterCss = ' iknl-filters-list-menu__box--no-gutter';
    const marginBottomCss = ' iknl-filters-list-menu__box--margin-bottom';
    if (filterMenuItemConfig) {
      if (this.layout === 'vertical') {
        filterMenuItemConfig.cssClass = filterMenuItemConfig.cssClass
          .concat(noGutterCss)
          .concat(marginBottomCss);
      }
      return filterMenuItemConfig;
    } else {
      return {
        hasTotal: false,
        totalValueLabel: '',
        multiple: false,
        filterName: '',
        cssClass: '',
        orderNr: 99,
      };
    }
  }

  private formatSelectionFilterItems(
    filterItems: ISelectionFilter[]
  ): ISelectionFilterTemplate[] {
    return filterItems.map((filterItem: ISelectionFilter) => {
      const meta: ISelectionFilterConfig = this.getSelectionFilterTemplateMeta(
        filterItem.filterName
      );
      const mappedFilterItem: ISelectionFilterTemplate = {
        ...filterItem,
        template: meta,
      };
      return mappedFilterItem;
    });
  }
}
