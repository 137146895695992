import { IChartConfig } from '@nkr-online/interfaces';
import { IDataRow, IDataRowCollection } from '@nkr-online/repo';
import Highcharts from 'highcharts';
import { ChartSeriesService } from './chart-series.service';

export class ChartTooltipService {
  public getTooltipValue(
    chartOptions: Highcharts.Options,
    chartConfig: IChartConfig,
    chartData: IDataRowCollection,
    appTranslations: any,
    viewType: string
  ): Highcharts.TooltipOptions {
    const scopedThis = this;
    return {
      useHTML: true,
      // Highcharts does not support fat arrow notation
      // tslint:disable-next-line
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return scopedThis.renderTooltip(
          chartOptions,
          chartData,
          chartConfig,
          this,
          appTranslations,
          viewType
        );
      },
    };
  }

  private countDecimals(x: number): number {
    if (x === null || Math.floor(x.valueOf()) === x.valueOf()) {
      return 0;
    }

    if (x.toString().indexOf('.') !== -1) {
      return x.toString().split('.')[1].length || 0;
    } else {
      return 0;
    }
  }

  private renderTooltip(
    chartOptions: Highcharts.Options,
    chartData: IDataRowCollection,
    chartConfig: IChartConfig,
    tooltipFormatterContext: Highcharts.TooltipFormatterContextObject,
    appTranslations: any,
    viewType: string
  ): string | false {
    const chartSeriesService = new ChartSeriesService();
    // TODO: Don't know why 'this.key' from highcharts is of type 'number'
    const seriesKey: string =
      chartOptions.series.length > 1
        ? tooltipFormatterContext.series.name
        : null;

    let groupedXAxisKey = undefined;
    let xAxisKey: string;
    if (
      chartConfig.plotOptions &&
      chartConfig.plotOptions.groupedXAxisLabels &&
      chartConfig.plotOptions.groupedXAxisLabels.enabled === true
    ) {
      const category: any = tooltipFormatterContext.x;
      xAxisKey = category.parent.name.toString();
      groupedXAxisKey = category.name.toString();
    } else {
      xAxisKey = tooltipFormatterContext.x.toString();
    }
    const currentDataItem: IDataRow =
      chartSeriesService.getDataRowBySelectionFilterKeys(
        {
          xAxisKey,
          seriesKey,
          groupedXAxisKey,
        },
        chartData,
        chartConfig,
        viewType
      );
    const currentDenominator =
      currentDataItem.meta.denominator !== 'NotAllowed'
        ? parseInt(currentDataItem.meta.denominator, 10)
        : null;
    const currentNumerator =
      currentDataItem.meta.numerator !== 'NotAlllowed'
        ? parseInt(currentDataItem.meta.numerator, 10)
        : null;

    if (currentDataItem.meta.hasDenominatorCutoff === true) {
      let text = `${appTranslations.services.chart.tooltip.denominator}`;
      if (currentDataItem.meta.denominator !== 'NotAllowed') {
        text = text.concat(
          ` (${Highcharts.numberFormat(
            currentDenominator,
            this.countDecimals(currentDenominator),
            ',',
            '.'
          )})`
        );
      }
      text = text.concat(
        ` ${appTranslations.services.chart.tooltip.denominatorCutOff}`
      );
      return text;
    }

    const titleTemplate = `<b>${tooltipFormatterContext.x}</b><br>`;
    let valueTemplate = ``;
    if (seriesKey !== null) {
      valueTemplate = `
            <span class="iknl-benchmark-chart__serie-circle" style="background-color: ${
              tooltipFormatterContext.color
            }; border-radius: 0;"></span> ${seriesKey}: ${Highcharts.numberFormat(
        currentDataItem.value,
        this.countDecimals(currentDataItem.value),
        ',',
        '.'
      )}${currentDataItem.meta.unit === 'Percentage' ? '%' : ''} <br>
            `;
    } else {
      valueTemplate = `
            ${
              appTranslations.services.chart.tooltip.value
            }: ${Highcharts.numberFormat(
        currentDataItem.value,
        this.countDecimals(currentDataItem.value),
        ',',
        '.'
      )}${currentDataItem.meta.unit === 'Percentage' ? '%' : ''} <br>
            `;
    }

    const denominatorTemplate = currentNumerator
      ? `
        ${
          appTranslations.services.chart.tooltip.numerator
        }: ${Highcharts.numberFormat(
          currentNumerator,
          this.countDecimals(currentNumerator),
          ',',
          '.'
        )} <br>
        `
      : '';

    const numeratorTemplate = currentDenominator
      ? `
        ${
          appTranslations.services.chart.tooltip.denominator
        }: ${Highcharts.numberFormat(
          currentDenominator,
          this.countDecimals(currentDenominator),
          ',',
          '.'
        )}
        `
      : '';

    return ''.concat(
      titleTemplate,
      valueTemplate,
      denominatorTemplate,
      numeratorTemplate
    );
  }
}
