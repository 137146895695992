import { Injectable } from '@angular/core';
import { OAuthService, TokenResponse, OAuthEvent } from 'angular-oauth2-oidc';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthLoginService {
  constructor(
    private authService: AuthService,
    private oauthService: OAuthService
  ) {}

  public runInitialLoginSequence(): Promise<void> {
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:

    return (
      this.oauthService
        .loadDiscoveryDocument()
        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect
        .then(() => {
          return this.oauthService.tryLoginCodeFlow();
        })
        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            return Promise.resolve();
          }
          return this.trySilentLogin();
        })
        .then(() => {
          this.authService.isDoneLoading = true;
        })
        .catch(() => {
          this.authService.isDoneLoading = true;
        })
    );
  }

  private trySilentLogin(): Promise<void> {
    // TRY SILENT LOGIN:
    // Try to log in via a refresh because then we can prevent
    // needing to redirect the user:
    return this.tryNoPromptRefresh()
      .then(() => {
        return Promise.resolve();
      })
      .catch((result) => {
        this.checkIfInteractiveLoginNeeded(result);

        // We can't handle the truth, just pass on the problem to the
        // next handler.
        return Promise.reject(result);
      });
  }

  private tryNoPromptRefresh(): Promise<TokenResponse | OAuthEvent> {
    if (this.oauthService.getRefreshToken()) {
      console.log('Found a refresh token, trying to use it.');
      return this.oauthService.refreshToken();
    }

    console.log('Found no refresh token, trying iframe based refresh');
    return this.oauthService.silentRefresh();
  }

  private checkIfInteractiveLoginNeeded(result: any) {
    // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
    // Only the ones where it's reasonably sure that sending the
    // user to the IdServer will help.
    const errorResponsesRequiringUserInteraction = [
      'interaction_required',
      'login_required',
      'account_selection_required',
      'consent_required',
    ];

    if (
      result &&
      result.reason &&
      errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0
    ) {
      // ASK FOR LOGIN:
      // At this point we know for sure that we have to ask the
      // user to log in, so we redirect them to the IdServer to
      // enter credentials.
      //
      // Enable this to ALWAYS force a user to login.
      this.oauthService.initLoginFlow();
    }
  }
}
