import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { INavLink, INavLinkCollection } from '@nkr-online/interfaces';

@Component({
  selector: 'iknl-view-tabs',
  templateUrl: './view-tabs.component.html',
  styleUrls: ['./view-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewTabsComponent implements OnChanges {
  @Input() public links: INavLinkCollection;
  @Input() public showToggler: boolean;
  @Input() public activeLink: string;
  @Input() public view: boolean;
  @Output() private viewChange: EventEmitter<boolean> = new EventEmitter();
  @Output() private routeChange: EventEmitter<INavLink> = new EventEmitter();

  public menuVisible = false;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.view) {
      this.menuVisible = false;
    }
  }

  public onRouteLinkClicked(clickedLink: INavLink): void {
    this.routeChange.next(clickedLink);
  }

  public toggleMenu() {
    if (!this.view) {
      this.menuVisible = !this.menuVisible;
    } else {
      this.toggleDisplay();
    }
  }

  public toggleDisplay() {
    const newView = !this.view;
    this.viewChange.next(newView);
  }
}
