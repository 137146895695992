import {
  IFilterValueItemDto,
  FilterValueItemDto,
  IFilterValueItemDtoCollection,
} from './selection-filter-value.dto.interface';
import {
  IFilterValueItem,
  FilterValueItem,
} from './selection-filter-value.interface';

export class FilterValueMapper {
  public static possibleValuesResponseToDomain(input: IFilterValueItemDto) {
    return new FilterValueItem({
      filterValueName: input.filterValueName,
      filterValueLabel: input.filterValueLabel,
    });
  }

  public static selectedValuesResponseToDomain(
    input: string,
    possibleFilterValues: IFilterValueItemDtoCollection
  ): IFilterValueItem {
    const currentValue = possibleFilterValues.find(
      (possibleFilter: IFilterValueItemDto) =>
        possibleFilter.filterValueName === input
    );
    return new FilterValueItem({
      filterValueName: currentValue.filterValueName,
      filterValueLabel: currentValue.filterValueLabel,
    });
  }

  public static requestToDto(input: IFilterValueItem): IFilterValueItemDto {
    return new FilterValueItemDto({
      filterValueName: input.filterValueName,
    });
  }
}
