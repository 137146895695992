import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InsightsService } from '@nkr-online/services';
import { Subscription } from 'rxjs';
import { AppConfigService } from './services/app-config.service';
import { AuthLoginService, AuthService } from './services/auth/_index';
import moment from 'moment';

@Component({
  selector: 'iknl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public userInfo: any;
  public feedbackTranslations: any;

  public releaseInfoVisible = false;
  public releaseNotesUrl = '';

  private translateServiceListener: Subscription;
  private isAuthenticatedListener: Subscription;

  constructor(
    private authService: AuthService,
    private authLoginService: AuthLoginService,
    private translateService: TranslateService,
    private insightsService: InsightsService,
    appConfig: AppConfigService
  ) {
    const appConfigObj = appConfig.getConfig();

    const lastReleaseDate = appConfigObj['lastReleaseDate'];
    if (lastReleaseDate) {
      const momentBegin = moment(lastReleaseDate.value);
      const momentEnd = moment(momentBegin).add(3, 'M');
      const isBetween = moment().isBetween(momentBegin, momentEnd);
      this.releaseInfoVisible = isBetween;
      this.releaseNotesUrl = appConfigObj['releaseNotesUrl'].value;
    }

    this.authService.updateAuthConfig(
      appConfigObj.clientId.value,
      appConfigObj.scope.value,
      `https://login.microsoftonline.com/${appConfigObj.tenantId.value}/v2.0`,
      // `https://sts.windows.net/${appConfigObj.tenantId.value}/`,
      'code',
      '/home',
      true
    );

    this.isAuthenticatedListener =
      this.authService.canActivateProtectedRoutes$.subscribe(() => {
        this.userInfo = this.authService.userInfo;
        if (this.userInfo) {
          this.insightsService.setUserId(
            this.userInfo.email,
            this.userInfo.userId
          );
        }
      });

    this.translateServiceListener = this.translateService
      .get('feedback')
      .subscribe((res) => {
        this.feedbackTranslations = res;
      });

    this.authLoginService.runInitialLoginSequence();
  }

  public ngOnDestroy() {
    this.insightsService.clearUserId();
    this.isAuthenticatedListener.unsubscribe();
    this.translateServiceListener.unsubscribe();
  }

  public logoutChangeHandler() {
    this.insightsService.clearUserId();
    this.authService.logout();
  }
}
