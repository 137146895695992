import { IChartConfig } from '@nkr-online/interfaces';
import { IDataRowCollection } from '@nkr-online/repo';
import Highcharts from 'highcharts';
import { cloneDeep, uniqBy } from 'lodash';
import { SelectionFilterPrefixedNames } from 'src/app/pages/base.config';

export class ChartAxisService {
  private convertToNumberingScheme(indexNumber: number) {
    const baseChar = 'A'.charCodeAt(0);
    let letters = '';

    do {
      indexNumber -= 1;
      letters = String.fromCharCode(baseChar + (indexNumber % 26)) + letters;
      // tslint:disable-next-line: no-bitwise
      indexNumber = (indexNumber / 26) >> 0; // quick `floor`
    } while (indexNumber > 0);

    return letters;
  }

  private sortBenchmarkPartnership(
    categories: string[],
    chartConfig: IChartConfig,
    chartData: IDataRowCollection
  ): string[] {
    const clonedCategories = cloneDeep(categories)
      .map((fullLabel: string) => {
        const shortLabel = chartData.find((item) => {
          return (
            item.dataRowMappedFilters[chartConfig.xAxisSelectionFilter] ===
            fullLabel
          );
        }).dataRowMappedFilters[chartConfig.xAxisShortSelectionFilter];
        return shortLabel;
      })
      .sort();

    if (chartData && chartData[0] && chartData[0].meta.unit === 'Percentage') {
      clonedCategories.sort();
    } else {
      clonedCategories.sort((itemA: any, itemB: any) => {
        const totalValueA = chartData
          .filter(
            (dataRow) =>
              dataRow.dataRowMappedFilters[
                chartConfig.xAxisShortSelectionFilter
              ] === itemA
          )
          .map((dataRow) => dataRow.value)
          .reduce((valueA, valueB) => valueA + valueB);
        const totalValueB = chartData
          .filter(
            (dataRow) =>
              dataRow.dataRowMappedFilters[
                chartConfig.xAxisShortSelectionFilter
              ] === itemB
          )
          .map((dataRow) => dataRow.value)
          .reduce((valueA, valueB) => valueA + valueB);
        return totalValueA > totalValueB ? -1 : 1;
      });
    }

    const isAnoniminized =
      chartData.find((item) => item.meta.numerator === 'NotAllowed') !==
      undefined;
    if (isAnoniminized) {
      const currentShortEigenInstelling =
        chartConfig.eigenInstelling.filterValueName;

      if (currentShortEigenInstelling) {
        const ownHospitalIndex = clonedCategories.findIndex(
          (key: string) => key === currentShortEigenInstelling
        );

        const shortLabel = chartData.find((item) => {
          return (
            item.dataRowMappedFilters[chartConfig.xAxisShortSelectionFilter] ===
            clonedCategories[ownHospitalIndex]
          );
        }).dataRowMappedFilters[chartConfig.xAxisShortSelectionFilter];

        clonedCategories.splice(ownHospitalIndex, 1);

        clonedCategories.sort((labelA: string, labelB: string) => {
          if (labelA.length < labelB.length) {
            return -1;
          }
          return 1;
        });

        const firstWrongLetterItemIndex = clonedCategories.findIndex(
          (category, index) => {
            const currentLetterByIndex = this.convertToNumberingScheme(
              index + 1
            );
            const actualLetter = category;
            return currentLetterByIndex !== actualLetter;
          }
        );

        clonedCategories.splice(firstWrongLetterItemIndex, 0, shortLabel);
      } else {
        clonedCategories.sort((labelA: string, labelB: string) => {
          return labelA.length - labelB.length;
        });
      }
    }

    categories = clonedCategories;

    return categories;
  }

  private sortCategories(
    chartConfig: IChartConfig,
    categories: string[],
    viewType: string,
    chartData: IDataRowCollection,
    instellingGroepValue: string
  ): string[] {
    const perspective = chartConfig.chartName;

    if (viewType === 'trend') {
      categories = categories.sort();
    } else if (viewType === 'benchmark') {
      if (perspective === 'partnership') {
        categories = this.sortBenchmarkPartnership(
          categories,
          chartConfig,
          chartData
        );
        if (
          chartData &&
          chartData[0] &&
          chartData[0].meta.unit === 'Percentage'
        ) {
          const selectedInstellingGroepIndex = categories.findIndex(
            (item) => item === instellingGroepValue
          );
          categories.push(
            categories.splice(selectedInstellingGroepIndex, 1)[0]
          );
        }
      } else if (perspective === 'region') {
        const defaultRegionOrder: string[] = [
          'NO NL',
          'Oost NL',
          'Midden NL',
          'NW NL',
          'West NL',
          'ZW NL',
          'ZO NL',
        ];
        const regionGroupCategoryItem: string = categories.find(
          (item: string) => defaultRegionOrder.indexOf(item) === -1
        );
        if (regionGroupCategoryItem) {
          defaultRegionOrder.push(regionGroupCategoryItem);
        }
        categories = defaultRegionOrder;
      } else if (perspective === 'hospitalType') {
        categories = [
          'SAZ-ziekenhuizen',
          'STZ-ziekenhuizen',
          'UMC’s & AVL',
          'Nederland',
        ];
      }
    }

    return categories;
  }

  public getXAxisValues(
    chartConfig: IChartConfig,
    viewType: string,
    instellingGroepValue: string,
    chartData: IDataRowCollection,
    categories: IDataRowCollection
  ): Highcharts.XAxisOptions {
    let mappedCategories: string[] = [];
    if (viewType === 'trend') {
      mappedCategories = categories.map((item: any) => {
        return item.dataRowMappedFilters[chartConfig.xAxisSelectionFilter];
      });
    } else {
      mappedCategories = uniqBy(
        chartData,
        `dataRowMappedFilters["${chartConfig.xAxisSelectionFilter}"]`
      )
        .map(
          (dataRow) =>
            dataRow.dataRowMappedFilters[chartConfig.xAxisSelectionFilter]
        )
        .sort();
    }
    const sortedCategories = mappedCategories
      ? this.sortCategories(
          chartConfig,
          mappedCategories,
          viewType,
          chartData,
          instellingGroepValue
        )
      : undefined;

    let subcategoriesLength = 1;
    let newCategories: any = sortedCategories;
    if (
      chartConfig.plotOptions &&
      chartConfig.plotOptions.groupedXAxisLabels &&
      chartConfig.plotOptions.groupedXAxisLabels.enabled === true
    ) {
      const subcategories = uniqBy(
        chartData,
        `dataRowMappedFilters["${chartConfig.plotOptions.groupedXAxisLabels.extraAxisFilterName}"]`
      )
        .map((item) => {
          return item.dataRowMappedFilters[
            chartConfig.plotOptions.groupedXAxisLabels.extraAxisFilterName
          ];
        })
        .sort();
      subcategoriesLength = subcategories.length;
      newCategories = sortedCategories.map((category: string) => {
        return {
          name: category,
          categories: subcategories,
        };
      });
    }

    const plotBandsEnabled =
      newCategories &&
      chartConfig.plotOptions &&
      chartConfig.plotOptions.plotBands &&
      chartConfig.plotOptions.plotBands.enabled;
    let startIndex: number;
    let endIndex: number;
    if (plotBandsEnabled && chartConfig.eigenInstelling) {
      const currentShortEigenInstellingen = chartData.filter(
        (item) =>
          item.dataRowMappedFilters[
            SelectionFilterPrefixedNames.InstellingShort
          ] === chartConfig.eigenInstelling.filterValueName
      );

      currentShortEigenInstellingen.forEach((currentShortEigenInstelling) => {
        const currentShortEigenInstellingValue = currentShortEigenInstelling
          ? currentShortEigenInstelling.dataRowMappedFilters[
              SelectionFilterPrefixedNames.InstellingShort
            ]
          : '';

        if (
          chartConfig.plotOptions &&
          chartConfig.plotOptions.groupedXAxisLabels &&
          chartConfig.plotOptions.groupedXAxisLabels.enabled === true
        ) {
          const categoryIndex = newCategories.findIndex(
            (category: any) =>
              category.name === currentShortEigenInstellingValue
          );
          startIndex = categoryIndex * subcategoriesLength;
          endIndex = startIndex + subcategoriesLength;
        } else {
          startIndex = newCategories.findIndex(
            (category: string) => category === currentShortEigenInstellingValue
          );
          endIndex = startIndex + 1;
        }
      });
    }
    return {
      title: {
        align: 'low',
        text: chartConfig.xAxisText,
      },
      labels: {
        // HACK added formatter for long labels over 40 characters
        // Highcharts does not support fat arrow notation
        // tslint:disable-next-line
        formatter: function () {
          const valueString = this.value.toString();
          if (valueString.length < 40) {
            return valueString;
          } else {
            const maxLabelLength = 40;
            return valueString.substring(0, maxLabelLength).concat('...');
          }
        },
        rotation: 0,
      },
      plotBands: [
        {
          from: startIndex !== undefined ? startIndex - 0.5 : undefined,
          to: startIndex !== undefined ? endIndex - 0.5 : undefined,
          color: '#c3ecf98a',
        },
      ],
      categories: newCategories,
    };
  }

  public getYAxisValues(
    chartConfig: IChartConfig,
    chartData: IDataRowCollection
  ): Highcharts.YAxisOptions {
    const serieConcatenatedRows: {
      serieValue: string;
      concatenatedValue: number;
    }[] = [];

    chartData.forEach((dataRow) => {
      const currentSerieValue = dataRow.dataRowFilters.find(
        (item) => item.filterName === chartConfig.seriesSelectionFilter
      ).filterValue;

      const concatenatedValue = chartData
        .filter(() => {
          return dataRow.dataRowFilters.find(
            (item) => item.filterValue === currentSerieValue
          );
        })
        .map((dataRowItem) => dataRowItem.value)
        .reduce((a: number, b: number) => a + b);

      serieConcatenatedRows.push({
        serieValue: currentSerieValue,
        concatenatedValue,
      });
    });

    const isPercentage: boolean =
      chartData && chartData.length && chartData[0].meta.unit === 'Percentage';

    let ceiling;
    let endOnTick;
    if (isPercentage) {
      ceiling = 100;
      endOnTick = true;
    } else {
      ceiling = Math.max(
        ...serieConcatenatedRows.map(
          (chartDataItem) => chartDataItem.concatenatedValue
        )
      );
      endOnTick = false;
    }

    return {
      ceiling,
      endOnTick,
      allowDecimals: true,
      minTickInterval: 1,
      labels: {
        // Highcharts does not support fat arrow notation
        // tslint:disable-next-line
        formatter: function () {
          const numberValue: any = this.value;
          const defaultFormat = Highcharts.numberFormat(
            numberValue,
            0,
            ',',
            '.'
          );
          return isPercentage ? defaultFormat + '%' : defaultFormat;
        },
      },
      title: {
        text: chartConfig.yAxisText,
      },
    };
  }
}
