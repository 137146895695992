<div class="iknl-filters-list-menu">
  <div class="iknl-filters-list-menu__wrapper row no-gutters">
    <div
      *ngFor="let filter of filtersList"
      class="iknl-filters-list-menu__box"
      [ngClass]="filter.template.cssClass"
    >
      <iknl-selection-filter-box
        [currentFilter]="filter"
        (modalTabChange)="modalTabChangeHandler($event)"
        (currentFilterChange)="filterValueChangeHandler($event)"
      ></iknl-selection-filter-box>
    </div>
  </div>
</div>
