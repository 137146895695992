import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, LandingAuthGuard } from './services/auth/_index';

const initialRoute = 'trend';
const landingRoute = 'home';

const routes = [
  {
    path: '',
    redirectTo: initialRoute,
    pathMatch: 'full',
  },
  // {
  //   path: 'benchmark',
  //   loadChildren: () =>
  //     import('./pages/benchmark/benchmark.module').then(
  //       (m) => m.BenchmarkModule
  //     ),
  //   canActivate: [AuthGuard],
  //   data: { initialRoute, landingRoute },
  // },
  {
    path: 'trend',
    loadChildren: () =>
      import('./pages/trend/trend.module').then((m) => m.TrendModule),
    canActivate: [AuthGuard],
    data: { initialRoute, landingRoute },
  },
  {
    path: 'bibliotheek',
    loadChildren: () =>
      import('./pages/library/library.module').then((m) => m.LibraryModule),
    canActivate: [AuthGuard],
    data: { initialRoute, landingRoute },
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/landing/landing.module').then((m) => m.LandingModule),
    canActivate: [LandingAuthGuard],
    data: { initialRoute, landingRoute },
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/error/error.module').then((m) => m.ErrorModule),
    data: { initialRoute, landingRoute },
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation: 'enabledNonBlocking',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
