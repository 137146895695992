<div
  *ngIf="userInfo"
  [ngClass]="{
    'iknl-feedback-form': true,
    'iknl-feedback-form--active': feedbackFormOpened
  }"
>
  <button
    type="button"
    class="iknl-feedback-form__button"
    (click)="toggleFeedbackForm()"
  >
    {{ feedbackTranslations.buttonLabel }}
  </button>
  <div class="iknl-feedback-form__wrapper">
    <form
      (ngSubmit)="submitFeedbackHandler()"
      [formGroup]="feedbackFormGroup"
      lass="iknl-feedback-form__form"
    >
      <div class="iknl-feedback-form__group">
        <label class="iknl-feedback-form__label" for="userFullName">{{
          feedbackTranslations.inputs.userFullName
        }}</label>
        <input
          type="text"
          id="userFullName"
          formControlName="userFullName"
          [ngClass]="{
            'iknl-feedback-form__input': true
          }"
        />
      </div>
      <div class="iknl-feedback-form__group">
        <label class="iknl-feedback-form__label" for="userEmail">{{
          feedbackTranslations.inputs.userEmail
        }}</label>
        <input
          type="email"
          id="userEmail"
          formControlName="userEmail"
          [readonly]="true"
          [ngClass]="{
            'iknl-feedback-form__input': true,
            'iknl-feedback-form__input--readonly': true
          }"
        />
      </div>
      <div class="iknl-feedback-form__group">
        <label class="iknl-feedback-form__label" for="feedback">
          {{ feedbackTranslations.inputs.feedback }}</label
        >
        <textarea
          type="email"
          id="feedback"
          formControlName="feedback"
          class="iknl-feedback-form__input"
          rows="4"
        ></textarea>
      </div>
      <div class="iknl-feedback-form__group">
        <input
          type="checkbox"
          id="allowContact"
          formControlName="allowContact"
          class="iknl-feedback-form__checkbox"
        />
        <label for="allowContact">
          {{ feedbackTranslations.inputs.allowContact }} </label
        ><br />
      </div>
      <div class="iknl-feedback-form__group">
        <button
          type="submit"
          [ngClass]="{
            'iknl-feedback-form__submit': true,
            'iknl-feedback-form__submit--invalid':
              isBusy === true || feedbackFormGroup.invalid
          }"
        >
          {{ feedbackTranslations.submit }}
        </button>
        <small class="iknl-feedback-form__message" *ngIf="formMessage">{{
          formMessage
        }}</small>
      </div>
    </form>
  </div>
</div>
