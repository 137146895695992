<router-outlet></router-outlet>

<iknl-new-features-popup
  *ngIf="releaseInfoVisible"
  [releaseNotesUrl]="releaseNotesUrl"
></iknl-new-features-popup>

<iknl-feedback
  *ngIf="userInfo && feedbackTranslations"
  [userInfo]="userInfo"
  [feedbackTranslations]="feedbackTranslations"
></iknl-feedback>
