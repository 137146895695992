export interface ICondition {
  filterProperty: string;
  filterOperator: string;
  filterValue: string;
}
export type IConditionCollection = ICondition[];

export class Condition implements ICondition {
  public filterProperty: string;
  public filterOperator: string;
  public filterValue: string;
  constructor(input: ICondition) {
    this.filterProperty = input.filterProperty;
    this.filterOperator = input.filterOperator;
    this.filterValue = input.filterValue;
  }
}
