import { IChartConfig } from '@nkr-online/interfaces';
import { IDataRow, IDataRowCollection } from '@nkr-online/repo';
import Highcharts from 'highcharts';
import { colors } from './colors';
import { uniqBy } from 'lodash';

export class ChartSeriesService {
  public getChartSeries(
    chartConfig: IChartConfig,
    chartData: IDataRowCollection,
    serieOptions: IDataRowCollection,
    categories: string[],
    viewType: string
  ): Highcharts.SeriesOptionsType[] {
    const newSeries: Highcharts.SeriesOptionsType[] = [];

    const denominatorCutoffCategories: any[] = [];
    serieOptions
      .map((serieOption: any) => {
        return serieOption.dataRowMappedFilters[
          chartConfig.seriesSelectionFilter
        ];
      })
      .sort()
      .forEach((serieOption: any, index: number) => {
        const data: any[] = [];
        if (categories) {
          categories.forEach((category: string | any) => {
            const xAxisFilterItem =
              viewType === 'benchmark' &&
              chartConfig.chartName === 'partnership'
                ? chartConfig.xAxisShortSelectionFilter
                : chartConfig.xAxisSelectionFilter;

            const matchingRows = chartData.filter((rowItem: any) => {
              if (
                chartConfig.plotOptions &&
                chartConfig.plotOptions.groupedXAxisLabels &&
                chartConfig.plotOptions.groupedXAxisLabels.enabled === true
              ) {
                return (
                  rowItem.dataRowMappedFilters[
                    chartConfig.seriesSelectionFilter
                  ] === serieOption &&
                  rowItem.dataRowMappedFilters[xAxisFilterItem] ===
                    category.name
                );
              } else {
                return (
                  rowItem.dataRowMappedFilters[
                    chartConfig.seriesSelectionFilter
                  ] === serieOption &&
                  rowItem.dataRowMappedFilters[xAxisFilterItem] === category
                );
              }
            });

            let uniqueGroups: IDataRow[] = [matchingRows[0]];
            if (
              chartConfig.plotOptions &&
              chartConfig.plotOptions.groupedXAxisLabels &&
              chartConfig.plotOptions.groupedXAxisLabels.enabled === true
            ) {
              uniqueGroups = uniqBy(
                chartData.filter((rowItem: any) => {
                  return (
                    rowItem.dataRowMappedFilters[xAxisFilterItem] ===
                    category.name
                  );
                }),
                `dataRowMappedFilters["${chartConfig.plotOptions.groupedXAxisLabels.extraAxisFilterName}"]`
              ).sort((rowA: IDataRow, rowB: IDataRow) => {
                const selectorA =
                  rowA.dataRowMappedFilters[
                    chartConfig.plotOptions.groupedXAxisLabels
                      .extraAxisFilterName
                  ];
                const selectorB =
                  rowB.dataRowMappedFilters[
                    chartConfig.plotOptions.groupedXAxisLabels
                      .extraAxisFilterName
                  ];
                return selectorA > selectorB ? 1 : -1;
              });
            }

            uniqueGroups.forEach((group: IDataRow, index: number) => {
              let currentRow;
              if (!group) {
                currentRow = undefined;
              } else {
                const currentGroupAxisFilterName =
                  group.dataRowMappedFilters[
                    chartConfig.plotOptions.groupedXAxisLabels
                      .extraAxisFilterName
                  ];
                currentRow = matchingRows.find((row: IDataRow) => {
                  const currentMatchingRouwAxisFilterName =
                    row.dataRowMappedFilters[
                      chartConfig.plotOptions.groupedXAxisLabels
                        .extraAxisFilterName
                    ];
                  return (
                    currentGroupAxisFilterName ===
                    currentMatchingRouwAxisFilterName
                  );
                });
              }

              if (currentRow) {
                if (!currentRow.value) {
                  currentRow.value = 0;
                }

                let dataLabels = {};

                if (
                  currentRow.meta &&
                  currentRow.meta.hasDenominatorCutoff === true &&
                  category &&
                  denominatorCutoffCategories.indexOf(category) === -1
                ) {
                  dataLabels = {
                    enabled: true,
                    allowOverlap: true,
                    formatter: () => {
                      // tslint:disable-next-line: max-line-length
                      return '<img src="/assets/icons/chart-warning-icon.png" style="width: 13px; opacity: .75;" class="iknl-chart__warning-icon"></img>';
                    },
                    shape: 'rect',
                    verticalAlign: 'bottom',
                    align: 'center',
                    useHTML: true,
                  };
                  denominatorCutoffCategories.push(category);
                  data.push({
                    y: 0,
                    minPointLength: 0,
                    name: category,
                    dataLabels,
                  });
                } else if (
                  currentRow.meta &&
                  currentRow.meta.hasDenominatorCutoff === false &&
                  denominatorCutoffCategories.indexOf(category) === -1
                ) {
                  data.push({
                    y: currentRow.value || 0,
                    minPointLength: 4,
                    name: category,
                    dataLabels,
                  });
                } else {
                  data.push(null);
                }
              } else {
                data.push(null);
              }
            });
          });
        }

        newSeries.push({
          // id: currentSerieName,
          name: serieOption,
          color: colors[index % colors.length],
          data,
          type: 'column',
          maxPointWidth: 40,
        });
      });
    return newSeries.reverse();
  }

  private pushDataItemToSerie(): void {}

  public getDataRowBySelectionFilterKeys(
    keys: {
      seriesKey: string | false;
      xAxisKey: string;
      groupedXAxisKey?: string;
    },
    datapoints: IDataRowCollection,
    chartConfig: IChartConfig,
    viewType: string
  ): IDataRow {
    const xAxisFilterItem =
      viewType === 'benchmark' && chartConfig.chartName === 'partnership'
        ? chartConfig.xAxisShortSelectionFilter
        : chartConfig.xAxisSelectionFilter;
    return datapoints.find((dataItem: IDataRow) => {
      let xAxisMatch;
      const defaultXAxisMatch =
        dataItem.dataRowMappedFilters[xAxisFilterItem] === keys.xAxisKey;
      if (keys && keys.groupedXAxisKey !== undefined) {
        xAxisMatch =
          defaultXAxisMatch &&
          dataItem.dataRowMappedFilters[
            chartConfig.plotOptions.groupedXAxisLabels.extraAxisFilterName
          ] === keys.groupedXAxisKey;
      } else {
        xAxisMatch = defaultXAxisMatch;
      }

      if (keys.seriesKey !== false && keys.seriesKey !== null) {
        if (datapoints.length > 1) {
          return (
            xAxisMatch &&
            dataItem.dataRowMappedFilters[chartConfig.seriesSelectionFilter] ===
              keys.seriesKey
          );
        } else {
          return xAxisMatch;
        }
      } else {
        return xAxisMatch;
      }
    });
  }
}
