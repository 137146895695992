export interface IFilterValueItemDto {
  filterValueName: string;
  filterValueLabel?: string;
}
export type IFilterValueItemDtoCollection = IFilterValueItemDto[];

export class FilterValueItemDto implements IFilterValueItemDto {
  public filterValueName;
  public filterValueLabel;

  constructor(input: IFilterValueItemDto) {
    this.filterValueName = input.filterValueName;
    this.filterValueLabel = input.filterValueLabel;
  }
}
