export enum AuthMessages {
  LoginRequired = 'login_required',
  InteractionRequired = 'interaction_required',
  InvalidResource = 'invalid_resource',
  TemporarilyUnavailable = 'temporarily_unavailable',
  ServerError = 'server_error',
  AccessDenied = 'access_denied',
  UnauthorizedClient = 'unauthorized_client',
  InvalidRequest = 'invalid_request',
}
