import { AuthConfig } from 'angular-oauth2-oidc';

export const getAuthConfig = (
  clientId: string,
  scope: string,
  issuer: string,
  responseType: string,
  useSilentRefresh: boolean,
  landingEndpoint: string,
  redirectUri?: string
): AuthConfig => {
  return {
    issuer,
    clientId,
    responseType,
    postLogoutRedirectUri: window.location.origin + '/#' + landingEndpoint,
    redirectUri: redirectUri || window.location.origin,
    strictDiscoveryDocumentValidation: false,
    silentRefreshRedirectUri: window.location.origin + '/silent-renew.html',
    scope, // Ask offline_access to support refresh token refreshes
    useSilentRefresh, // Needed for Code Flow to suggest using iframe-based refreshes
    sessionChecksEnabled: false,
    showDebugInformation: false, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  };
};
