import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  public blobToFile = (response: Blob, fileName: string): void => {
    saveAs(response, fileName);
  };
}
