import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  OnInit,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { IChartOptions } from '@nkr-online/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

class ChartItemsTuple {
  name: string;
  active: boolean;
  constructor(name: string, active: boolean) {
    this.name = name;
    this.active = active;
  }
}

@Component({
  selector: 'iknl-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent implements OnInit {
  @Input() public title: string;
  @Input() public changedOnDate = '';
  @Input() public invalidFilters: boolean;
  @Input() public emptyFilters: boolean;
  @Input() public activeChartOptions: IChartOptions;
  @Input() public chartsUpdated: Observable<void>;
  @Output() private infoButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output()
  public confirmedChartOptions: EventEmitter<
    IChartOptions
  > = new EventEmitter();
  public dropdownOpened = false;
  public activeChartType: string;
  public activeChartItems: ChartItemsTuple[] = [];
  public isLoadingChartOptions: boolean = false;

  constructor(private translateService: TranslateService) {}

  public ngOnInit() {
    this.activeChartType = this.getSelectedChartTypeLabel(
      this.activeChartOptions
    );
    this.chartsUpdated.subscribe(() => this.chartOptionsUpdated());
    this.setSelectedChartItemTuple();
  }

  public toggleChartOptions() {
    this.setSelectedChartType(this.activeChartType);
    this.activeChartItems.forEach(item => this.resetSelectedChartItems(item));
    this.dropdownOpened = !this.dropdownOpened;
  }

  public confirmChartOptions() {
    this.isLoadingChartOptions = true;
    let selectedType = this.getSelectedChartTypeLabel(this.activeChartOptions);
    if (this.activeChartOptions) {
      this.activeChartType = selectedType;
      this.confirmedChartOptions.emit(this.activeChartOptions);
      this.setSelectedChartItemTuple();
    }
  }

  public chartOptionsUpdated() {
    this.isLoadingChartOptions = false;
    this.dropdownOpened = false;
  }

  public setSelectedChartType(selectedValue: string) {
    this.activeChartOptions.types.forEach(
      type => (type.selected = type.label == selectedValue ? true : false)
    );
  }

  public setSelectedChartItemTuple() {
    this.activeChartOptions.items.forEach(item =>
      this.activeChartItems.push(new ChartItemsTuple(item.name, item.active))
    );
  }
  public resetSelectedChartItems(chartItemTuple: ChartItemsTuple) {
    this.activeChartOptions.items.forEach(
      item =>
        (item.active =
          item.name == chartItemTuple.name
            ? chartItemTuple.active
            : item.active)
    );
  }

  private getSelectedChartTypeLabel(chartOptions: IChartOptions): string {
    let selectedOption = chartOptions.types.find(type => type.selected);
    return selectedOption ? selectedOption.label : '';
  }

  public anyItemEnabled(): boolean {
    let items = this.activeChartOptions.items;
    if (items.length) {
      return items.some(item => !item.disabled) ? true : false;
    } else {
      return false;
    }
  }

  public trackChartSettingByItem(item: any) {
    return item.name;
  }

  public emitInfoButtonClicked() {
    this.infoButtonClicked.emit();
  }
}
