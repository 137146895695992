import { IFilterValueItemDto } from '../selection-filter-value/selection-filter-value.dto.interface';
import { IFilterValueItem } from '../selection-filter-value/selection-filter-value.interface';
import { FilterValueMapper } from '../selection-filter-value/selection-filter-value.mapper';
import { RepoMapper } from '../repo.mapper';
import {
  ISelectionFilterDto,
  SelectionFilterDto,
} from './selection-filter.dto.interface';
import {
  ISelectionFilter,
  SelectionFilter,
} from './selection-filter.interface';

export class SelectionFilterMapper extends RepoMapper {
  public static responseToDomain(input: ISelectionFilterDto): ISelectionFilter {
    return new SelectionFilter({
      filterName: input.filterName,
      filterLabel: input.filterLabel || 'Niet beschikbaar',
      isValid: input.isValid,
      possibleFilterValues: input.possibleFilterValues.map(
        (filterValue: IFilterValueItemDto) => {
          return FilterValueMapper.possibleValuesResponseToDomain(filterValue);
        }
      ),
      selectedFilterValues: input.serviceSelectedFilterValues.map(
        (filterValue: string) => {
          return FilterValueMapper.selectedValuesResponseToDomain(
            filterValue,
            input.possibleFilterValues
          );
        }
      ),
    });
  }

  public static requestToDto(input: ISelectionFilter): ISelectionFilterDto {
    return new SelectionFilterDto({
      filterName: input.filterName,
      userSelectedFilterValues: input.selectedFilterValues.map(
        (filterValue: IFilterValueItem) => {
          return FilterValueMapper.requestToDto(filterValue).filterValueName;
        }
      ),
    });
  }
}
