import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
require('highcharts-grouped-categories')(Highcharts);
exporting(Highcharts);
offlineExporting(Highcharts);

import {
  IChartConfig,
  ISerieVisibility,
  IChartOptions,
  ChartOptionType
} from '@nkr-online/interfaces';
import { ChartService, defaultchartOptions } from '@nkr-online/services';
import {
  IDataRowCollection,
  ISelectionFilterCollection,
} from '@nkr-online/repo';
import { Subject, Observable, Subscription } from 'rxjs';
import { SelectionFilterPrefixedNames } from 'src/app/pages/base.config';

@Component({
  selector: 'iknl-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent implements OnChanges, OnDestroy, OnInit {
  @Input() private appTranslations: any;
  @Input() public chartHeight: string;
  @Input() public chartData: IDataRowCollection = [];
  @Input() public chartConfig: IChartConfig;
  @Input() public serieOptions: IDataRowCollection = [];
  @Input() public xAxisOptions: IDataRowCollection = [];
  @Input() public pageTitle: string;
  @Input() public viewType: string;
  @Input() public view: boolean;
  @Input() public resizeFlag: boolean;
  @Input() public filters: ISelectionFilterCollection;
  @Input() public selectedChartType: ChartOptionType;
  @Input() private chartSerieVisibility: ISerieVisibility[];
  @Output() private chartSerieVisibilityChanged: EventEmitter<
    ISerieVisibility[]
  > = new EventEmitter();

  private _serieVisibility: Subject<ISerieVisibility> = new Subject();
  private serieVisibility$: Observable<ISerieVisibility> =
    this._serieVisibility.asObservable();
  private serieVisibilityListener: Subscription;

  @ViewChild('chartWrapper', { static: true }) public chartWrapper: ElementRef;

  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options = defaultchartOptions;

  private chart: Highcharts.Chart;

  constructor(private chartService: ChartService) {}

  public ngOnDestroy() {
    this.serieVisibilityListener.unsubscribe();
  }

  public ngOnInit() {
    this.serieVisibilityListener = this.serieVisibility$.subscribe(
      (newSerieVisibility: ISerieVisibility) => {
        const newSerieVisibilityItems = this.chartSerieVisibility.map(
          (oldSerieVisibility: ISerieVisibility) => {
            if (oldSerieVisibility.serieName === newSerieVisibility.serieName) {
              return newSerieVisibility;
            } else {
              return oldSerieVisibility;
            }
          }
        );
        this.chartSerieVisibilityChanged.emit(newSerieVisibilityItems);
      }
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.chartHeight) {
      this.resizeFlag = true;
    }

    if (changes.chartData || changes.viewType) {
      this.onDataChange();
    }
    if (
      changes.chartSerieVisibility &&
      changes.chartSerieVisibility.previousValue &&
      !changes.chartData
    ) {
      this.setSerieVisibility();
    }

    if (changes.resizeFlag) {
      this.onResize();
    }

    if (changes.view && !changes.chartData) {
      this.onResize();
    }
  }

  private onResize() {
    setTimeout(() => {
      const width = this.chartWrapper.nativeElement.clientWidth;
      if (width && this.chart) {
        this.chartOptions.chart.width = width;
        this.chart.update(this.chartOptions, true, true);
      }
    }, 250);
  }

  private setSerieVisibility() {
    this.chartOptions.series = this.chartOptions.series.map((serie: any) => {
      const visibilityItem = this.chartSerieVisibility.find(
        (serieVisibility: ISerieVisibility) =>
          serieVisibility.serieName === serie.name
      );
      serie.visible = visibilityItem ? visibilityItem.visibility : true;
      return serie;
    });
    if (this.chart) {
      this.chart.update(this.chartOptions, true, true);
    }
  }

  private onDataChange() {
    const currentInstellingGroep = this.filters.find((item) => {
      return item.filterName === SelectionFilterPrefixedNames.Instellinggroep;
    }).selectedFilterValues[0].filterValueName;
    this.chartOptions = this.chartService.getNewChartOptions(
      this.chartData,
      this.chartConfig,
      this.chartOptions,
      this.pageTitle,
      this._serieVisibility,
      this.selectedChartType,
      this.viewType,
      this.serieOptions,
      this.xAxisOptions,
      currentInstellingGroep,
      this.appTranslations
    );
    if (this.chart) {
      // remove series to make sure new series are animated
      while (this.chart.series.length) {
        this.chart.series[0].remove();
      }
      this.chart.update(this.chartOptions, true, true, true);
    }
  }

  public updateChartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
  }
}
