import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'iknl-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserInfoComponent {
  @Input() public userInfo: any;
  @Output() public logoutChange: EventEmitter<any> = new EventEmitter();

  public logout() {
    this.logoutChange.emit();
  }
}
