import { IFilterValueItemDtoCollection } from '../selection-filter-value/selection-filter-value.dto.interface';
import { IRepoDto } from '../repo.dto.interface';

export interface ISelectionFilterDto extends IRepoDto {
  filterName: string;
  filterLabel?: string;
  isValid?: boolean;
  possibleFilterValues?: IFilterValueItemDtoCollection;
  userSelectedFilterValues?: string[];
  serviceSelectedFilterValues?: string[];
}
export type ISelectionFilterDtoCollection = ISelectionFilterDto[];

export class SelectionFilterDto implements ISelectionFilterDto {
  public filterName;
  public filterLabel;
  public isValid;
  public possibleFilterValues;
  public userSelectedFilterValues;
  public serviceSelectedFilterValues;
  constructor(input: ISelectionFilterDto) {
    this.filterName = input.filterName;
    this.filterLabel = input.filterLabel;
    this.isValid = input.isValid;
    this.possibleFilterValues = input.possibleFilterValues;
    this.userSelectedFilterValues = input.userSelectedFilterValues;
    this.serviceSelectedFilterValues = input.serviceSelectedFilterValues;
  }
}
