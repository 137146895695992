<div class="iknl-selection-filter-box">
  <div class="iknl-selection-filter-box__wrapper">
    <h2 class="iknl-selection-filter-box__label">
      <b>{{ currentFilter.filterLabel }}</b>
      <div
        class="iknl-selection-filter-box__info-button"
        *ngIf="
          currentFilter.template.infoButton &&
          currentFilter.template.infoButton.enabled
        "
        (click)="openModalTab(currentFilter.template.infoButton.tab)"
      >
        i
      </div>
    </h2>

    <ng-select
      [items]="filterBoxOptions"
      [multiple]="currentFilter.template.multiple"
      bindLabel="filterValueLabel"
      placeholder="{{
        !currentFilter.isValid
          ? ('components.filterBox.invalidFilter' | translate)
          : currentFilter.template.hasTotal
          ? currentFilter.template.totalValueLabel
          : ('components.filterBox.placeholder' | translate)
      }}"
      [clearable]="
        (currentFilter.template.multiple && selectedFilterValues.length > 1) ||
        currentFilter.template.hasTotal
      "
      [ngModel]="
        currentFilter.template.multiple
          ? selectedFilterValues
          : selectedFilterValues[0]
      "
      (change)="valueChange($event)"
      clearAllText="{{ 'components.filterBox.clearAllText' | translate }}"
      notFoundText="{{ 'components.filterBox.notFoundText' | translate }}"
      addTagText="{{ 'components.filterBox.addTagText' | translate }}"
      [ngClass]="{
        'iknl-selection-filter-box__select': true,
        'iknl-selection-filter-box__select--invalid': !currentFilter.isValid
      }"
    >
    </ng-select>
  </div>
</div>
