import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ISelectionFilterTemplate } from '@nkr-online/interfaces';
import { ISelectionFilter, IFilterValueItem } from '@nkr-online/repo';

@Component({
  selector: 'iknl-selection-filter-box',
  templateUrl: './selection-filter-box.component.html',
  styleUrls: ['./selection-filter-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectionFilterBoxComponent implements OnChanges {
  @Input() public currentFilter: ISelectionFilterTemplate;
  @Output() public modalTabChange: EventEmitter<any> = new EventEmitter();
  @Output() public currentFilterChange: EventEmitter<ISelectionFilter> =
    new EventEmitter();
  public selectedFilterValues: any[] = [];
  public filterBoxOptions: any[] = [];
  public inputClearable = true;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.currentFilter) {
      this.selectedFilterValues = this.currentFilter.selectedFilterValues.map(
        (filterItem: IFilterValueItem) => {
          const matchingFilterItem =
            this.currentFilter.possibleFilterValues.find(
              (filterItemMatch: IFilterValueItem) => {
                return (
                  filterItemMatch.filterValueName === filterItem.filterValueName
                );
              }
            );
          return {
            filterValueName: filterItem.filterValueName,
            filterValueLabel: matchingFilterItem.filterValueLabel,
          };
        }
      );
      this.filterBoxOptions = this.currentFilter.possibleFilterValues.map(
        (filterItem: IFilterValueItem) => {
          return {
            filterValueName: filterItem.filterValueName,
            filterValueLabel: filterItem.filterValueLabel,
          };
        }
      );
    }
  }

  public openModalTab(value: any) {
    this.modalTabChange.emit(value);
  }

  public valueChange(value: any) {
    let currentSelectionFilter: ISelectionFilter;
    if (!this.currentFilter.template.multiple) {
      let newSelectedFilterValues: any[] = [];
      if (!value && this.currentFilter.template.hasTotal) {
        this.selectedFilterValues = [];
      } else {
        newSelectedFilterValues = [value];
        this.selectedFilterValues = [value];
      }
      // const selectedFilterValues = this.currentFilter.template.hasTotal

      currentSelectionFilter = {
        filterName: this.currentFilter.filterName,
        filterLabel: this.currentFilter.filterLabel,
        isValid: this.currentFilter.isValid,
        possibleFilterValues: this.currentFilter.possibleFilterValues,
        selectedFilterValues: newSelectedFilterValues,
      };
    } else {
      this.selectedFilterValues = value;
      currentSelectionFilter = {
        filterName: this.currentFilter.filterName,
        filterLabel: this.currentFilter.filterLabel,
        isValid: this.currentFilter.isValid,
        possibleFilterValues: this.currentFilter.possibleFilterValues,
        selectedFilterValues: value,
      };
    }
    this.currentFilterChange.emit(currentSelectionFilter);
  }
}
