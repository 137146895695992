<header class="iknl-page-header">
  <div class="iknl-page-header__row row">
    <ng-container *ngIf="invalidFilters && !emptyFilters">
      <div class="iknl-page-header__col iknl-page-header__col--invalid col-12">
        <div ngClass="iknl-page-header__invalid-icon" aria-label="Warning Icon"
          [inlineSVG]="'assets/icons/warning.svg'"></div>
        <h1 class="iknl-page-header__title">
          {{ 'components.pageHeader.invalidFiltersTitle' | translate }}
        </h1>
        <p class="iknl-page-header__description">
          {{ 'components.pageHeader.invalidFiltersDescription' | translate }}
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="!invalidFilters && emptyFilters">
      <div class="iknl-page-header__col iknl-page-header__col--invalid col-12">
        <div ngClass="iknl-page-header__invalid-icon" aria-label="Warning Icon"
          [inlineSVG]="'assets/icons/warning.svg'"></div>
        <h1 class="iknl-page-header__title">
          {{ 'components.pageHeader.emptyFiltersTitle' | translate }}
        </h1>
        <p class="iknl-page-header__description">
          {{ 'components.pageHeader.emptyFiltersDescription' | translate }}
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="!invalidFilters && !emptyFilters">
      <div class="iknl-page-header__col col-12 col-md-6 col-lg-6 col-xl-7">
        <h1 class="iknl-page-header__title">
          {{ title }}
        </h1>
        <p class="iknl-page-header__description">
          {{
          'components.pageHeader.changedOnDate'
          | translate: { date: changedOnDate }
          }}
        </p>
      </div>
      <div class="iknl-page-header__col col-12 col-md-6 col-lg-6 col-xl-5">
        <div class="iknl-page-header__button">
          <div class="
              iknl-page-header__button-inner
              iknl-page-header__button-inner--simple
            " (click)="emitInfoButtonClicked()">
            {{ 'components.pageHeader.informationButton' | translate }}
          </div>
        </div>
        <div class="iknl-page-header__button">
          <div class="iknl-page-header__button-inner" (click)="toggleChartOptions()">
            {{ 'components.pageHeader.chartOptionsButton' | translate }}
            <div [ngClass]="{
                'iknl-page-header__button-icon': true,
                'iknl-page-header__button-icon--opened': dropdownOpened
              }" aria-label="Chevron Icon" [inlineSVG]="'assets/icons/chevron.svg'"></div>
          </div>
          <ul [ngClass]="{
              'iknl-page-header__button-dropdown': true,
              'iknl-page-header__button-dropdown--opened': dropdownOpened
            }">
            <h2>
              {{ 'components.pageHeader.chartType' | translate }}
            </h2>
            <li *ngFor="let type of activeChartOptions.types" class="iknl-page-header__button-dropdown-item">
              <div [ngClass]="{
                  'iknl-page-header__button-dropdown-item-link': true,
                  'iknl-page-header__button-dropdown-item-link--active':
                    type.selected,
                  'iknl-page-header__button-dropdown-item-link--disabled':
                    type.disabled
                }" (click)="setSelectedChartType(type.label)">
                <div class="iknl-page-header__button-dropdown-item-link-icon" *ngIf="type.iconFileName"
                  aria-label="'Chart ' + type.label + ' Icon'"
                  [inlineSVG]="'assets/icons/' + type.iconFileName + '.svg'"></div>
                {{ type.label }}
              </div>
            </li>
            <h2 *ngIf="anyItemEnabled()">
              {{ 'components.pageHeader.chartOtherOptions' | translate }}
            </h2>
            <li *ngFor="
                let item of activeChartOptions.items;
                trackBy: trackChartSettingByItem
              " class="
                iknl-page-header__button-dropdown-item
                iknl-page-header__button-dropdown-item-checkbox
              ">
              <div [ngClass]="{
                  'iknl-page-header__button-dropdown-item-link': true,
                  'iknl-page-header__button-dropdown-item-link-checkbox': true,
                  'iknl-page-header__button-dropdown-item-link--disabled':
                    item.disabled,
                  'iknl-page-header__button-dropdown-item-link-checkbox--disabled':
                    item.disabled
                }">
                <input type="checkbox" [id]="item.name" [name]="item.name" [(ngModel)]="item.active" />
                <label [for]="item.name">
                  <div class="checkbox-toggle" data-unchecked="Off" data-checked="On"></div>
                </label>
                <p>
                  {{ item.label }}
                </p>
              </div>
            </li>
            <div class="iknl-page-header__confirm-button">
              <div [ngClass]="{
                  'iknl-page-header__confirm-button-inner': true,
                  'iknl-page-header__confirm-button-inner__loading': isLoadingChartOptions,
                  'iknl-page-header__confirm-button-inner__loading--disabled': isLoadingChartOptions
                }" (click)="confirmChartOptions()">
                {{ 'feedback.confirm' | translate }}
                <div class="iknl-page-header__confirm-button-inner__loader" *ngIf="isLoadingChartOptions">
                  <div class="iknl-page-header__confirm-button-inner__loader-inner"></div>
                </div>
              </div>
              <div [ngClass]="{
                  'iknl-page-header__confirm-button-inner': true,
                  'iknl-page-header__confirm-button-inner__loading--disabled': isLoadingChartOptions
                }" (click)="toggleChartOptions()">
                {{ 'feedback.cancel' | translate }}
              </div>
            </div>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
</header>