export interface IFilterValueItem {
  filterValueName: string;
  filterValueLabel: string;
}
export type IFilterValueItemCollection = IFilterValueItem[];

export class FilterValueItem implements IFilterValueItem {
  public filterValueName;
  public filterValueLabel;
  constructor(input: IFilterValueItem) {
    this.filterValueName = input.filterValueName;
    this.filterValueLabel = input.filterValueLabel;
  }
}
