import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ISelectionFilterCollection } from '@nkr-online/repo';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private _currentSelectionFilters: ISelectionFilterCollection = undefined;
  private _currentLibrarySelectionFilters: ISelectionFilterCollection =
    undefined;
  private _currentAuthorizationSelectionFilters: ISelectionFilterCollection =
    undefined;
  private _datasetReload: string = undefined;
  private _showAuthorizationFilters: boolean = undefined;

  constructor(private router: Router) {}

  public getCurrentSelectionFilterState(): {
    selectionFilters: ISelectionFilterCollection;
    librarySelectionFilters: ISelectionFilterCollection;
    authorizationFilters: ISelectionFilterCollection;
    datasetReload: string;
    showAuthorizationFilters: boolean;
  } {
    return {
      authorizationFilters: this._currentAuthorizationSelectionFilters,
      selectionFilters: this._currentSelectionFilters,
      librarySelectionFilters: this._currentLibrarySelectionFilters,
      datasetReload: this._datasetReload,
      showAuthorizationFilters: this._showAuthorizationFilters,
    };
  }

  public switchNkrOnlineView(
    route: '/benchmark' | '/trend' | '/bibliotheek',
    state: {
      filters: ISelectionFilterCollection;
      libraryFilters: ISelectionFilterCollection;
      authorizationFilters: ISelectionFilterCollection;
      datasetReload: string;
      showAuthorizationFilters: boolean;
    }
  ) {
    if (this.router.url.indexOf(route) !== -1) {
      return;
    }

    this._datasetReload = state.datasetReload;
    this._currentSelectionFilters = state.filters;
    this._currentLibrarySelectionFilters = state.libraryFilters;
    this._currentAuthorizationSelectionFilters = state.authorizationFilters;
    this._showAuthorizationFilters = state.showAuthorizationFilters;
    this.router.navigate([route]);
  }

  public changeRoute(route: string) {
    this.router.navigate([route]);
  }
}
