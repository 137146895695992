import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'iknl-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationModalComponent {
  @Input() public modalVisible: boolean;
  @Input() public selectedTab: 'definitie' | 'instellingen';
  @Input() public definitieAnalyse: string;
  @Input() public instellingGroep: string;
  @Input() public instellingen: string[];
  @Output() private closeModal: EventEmitter<any> = new EventEmitter();
  @Output() private changeTab: EventEmitter<any> = new EventEmitter();

  public emitChangeTab(tab: 'definitie' | 'instellingen') {
    this.changeTab.emit(tab);
  }

  public emitCloseModal() {
    this.closeModal.emit();
  }
}
