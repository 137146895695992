import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IAppConfig {
  key: string;
  value: string;
}

@Injectable()
export class AppConfigService {
  private appConfig: { [key: string]: IAppConfig } = {};

  constructor(private http: HttpClient) {}

  public loadAppConfig() {
    return this.http
      .get(environment.configUrl + '/config')
      .toPromise()
      .then((config: IAppConfig[]) => {
        config.forEach((configItem) => {
          this.appConfig[configItem.key] = configItem;
        });
      });
  }

  public getConfig(): { [key: string]: IAppConfig } {
    return this.appConfig;
  }
}
