import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/app-config.service';
import { RepoQuery } from '../repo.query';
import {
  ISelectionFilter,
  ISelectionFilterCollection,
} from '../selection-filter/selection-filter.interface';
import { SelectionFilterMapper } from '../selection-filter/selection-filter.mapper';
import { IDataRowDto, IDataRowDtoCollection } from './datarow.dto.interface';
import { DataRowMapper } from './datarow.mapper';
import {
  DataRowCollectionRequest,
  DataRowCollectionResponse,
} from './datarow.request.interface';
import { IChartOptions } from '@nkr-online/interfaces';

@Injectable()
export class DataRowQuery extends RepoQuery {
  constructor(protected http: HttpClient, appConfig: AppConfigService) {
    super(http, appConfig);
  }

  public getLibraryRows(
    perspective: string,
    filters: ISelectionFilterCollection,
    authorizationFilters: ISelectionFilterCollection,
    params?: any
  ): Observable<Blob> {
    const body: DataRowCollectionRequest = {
      perspective,
      options: [],
      request: {
        filters: filters
          .filter((filter: ISelectionFilter) => {
            return (
              filter.filterName !==
                'Z - AutorisatieModus[PortalAutorisatieModus]' &&
              filter.filterName !==
                'DimInstelling[HuidigInstellingOmschrijving]'
            );
          })
          .map((filter: ISelectionFilter) => {
            return SelectionFilterMapper.requestToDto(filter);
          }),
        authorizationFilters: authorizationFilters.map(
          (filter: ISelectionFilter) => {
            return SelectionFilterMapper.requestToDto(filter);
          }
        ),
      },
    };

    return this.http
      .post(`${this.apiUrl}/libraryData`, body, {
        headers: this.httpOptions.headers,
        observe: 'response',
        responseType: 'blob',
        params: {
          ...params,
        },
      })
      .pipe(
        map((libraryResponse) => {
          return libraryResponse.body;
        })
      );
  }

  public getBenchmarkData(
    perspective: string,
    filters: ISelectionFilterCollection,
    authorizationFilters: ISelectionFilterCollection,
    chartOptions: IChartOptions,
    params?: any
  ): Observable<DataRowCollectionResponse> {
    const anonymizedHospitalNamesValue = chartOptions.items.find(
      (item) => item.name == 'anonymizedHospitalNames'
    );
    const spliceYears = chartOptions.items.find(
      (item) => item.name == 'spliceYears'
    );
    const body: DataRowCollectionRequest = {
      perspective,
      options: [
        {
          key: 'anonymizedHospitalNames',
          value: anonymizedHospitalNamesValue
            ? anonymizedHospitalNamesValue.active
            : false,
        },
        {
          key: 'aggregatedYears',
          value: spliceYears ? !spliceYears.active : false,
        },
      ],
      request: {
        filters: filters
          .filter((filter: ISelectionFilter) => {
            return (
              filter.filterName !==
                'Z - AutorisatieModus[PortalAutorisatieModus]' &&
              filter.filterName !==
                'DimInstelling[HuidigInstellingOmschrijving]'
            );
          })
          .map((filter: ISelectionFilter) => {
            return SelectionFilterMapper.requestToDto(filter);
          }),
        authorizationFilters: authorizationFilters.map(
          (filter: ISelectionFilter) => {
            return SelectionFilterMapper.requestToDto(filter);
          }
        ),
      },
    };
    return this.http
      .post<DataRowCollectionResponse>(`${this.apiUrl}/benchmarkData`, body, {
        headers: this.httpOptions.headers,
        params: {
          ...params,
        },
      })
      .pipe(
        map((dataRowResponse: DataRowCollectionResponse) => {
          const dataRows: IDataRowDtoCollection = dataRowResponse.dataRows.map(
            (dataRow: IDataRowDto) => {
              return DataRowMapper.dataResponseToDomain(dataRow);
            }
          );
          return {
            ...dataRowResponse,
            dataRows,
          };
        })
      );
  }

  public getTrendRows(
    perspective: string,
    filters: ISelectionFilterCollection,
    authorizationFilters: ISelectionFilterCollection,
    chartOptions: IChartOptions,
    params?: any
  ): Observable<DataRowCollectionResponse> {
    const trendsByQuarterValue = chartOptions.items.find(
      (item) => item.name == 'trendsByQuarter'
    );
    const body: DataRowCollectionRequest = {
      perspective,
      options: [
        {
          key: 'trendsByQuarter',
          value: trendsByQuarterValue.active
            ? trendsByQuarterValue.active
            : false,
        },
      ],
      request: {
        filters: filters
          .filter((filter: ISelectionFilter) => {
            return (
              filter.filterName !==
                'Z - AutorisatieModus[PortalAutorisatieModus]' &&
              filter.filterName !==
                'DimInstelling[HuidigInstellingOmschrijving]'
            );
          })
          .map((filter: ISelectionFilter) => {
            return SelectionFilterMapper.requestToDto(filter);
          }),
        authorizationFilters: authorizationFilters.map(
          (filter: ISelectionFilter) => {
            return SelectionFilterMapper.requestToDto(filter);
          }
        ),
      },
    };

    return this.http
      .post<DataRowCollectionResponse>(`${this.apiUrl}/trendsData`, body, {
        headers: this.httpOptions.headers,
        params: {
          ...params,
        },
      })
      .pipe(
        map((dataRowResponse: DataRowCollectionResponse) => {
          const dataRows: IDataRowDtoCollection = dataRowResponse.dataRows.map(
            (dataRow: IDataRowDto) => {
              return DataRowMapper.dataResponseToDomain(dataRow);
            }
          );
          return {
            ...dataRowResponse,
            dataRows,
          };
        })
      );
  }
}
