import { Injectable } from '@angular/core';
import {
  Color,
  IChartConfig,
  ISerieVisibility,
  IChartOptions,
  ChartOptionType
} from '@nkr-online/interfaces';
import { IDataRowCollection } from '@nkr-online/repo';
import Highcharts from 'highcharts';
import { clone, cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { ChartAxisService } from './chart-axis.service';
import { ChartEventsService } from './chart-events.service';
import { ChartExportService } from './chart-export.service';
import { ChartSeriesService } from './chart-series.service';
import { ChartTooltipService } from './chart-tooltip.service';
import { colors } from './colors';

export const defaultchartOptions: Highcharts.Options = {
  lang: {},
  chart: {
    events: {},
    style: {
      fontFamily: `'Avenir LT Std', sans-serif`,
      fontWeight: '500',
    },
  },
  colors,
  credits: {
    enabled: false,
  },
  legend: {
    align: 'right',
    symbolRadius: 0,
  },
  navigation: {
    buttonOptions: {
      symbolStroke: Color.Black1,
      symbolStrokeWidth: 3.5,
      symbolSize: 16,
      buttonSpacing: 0,
      symbolX: 15.5,
      symbolY: 15,
      width: 30,
      height: 30,
    },
  },
  plotOptions: {
    series: {
      point: {
        events: {},
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
  },
  series: [],
  title: {
    text: '',
    align: 'left',
  },
  xAxis: {
    title: {
      text: '',
      align: 'low',
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  public getNewChartOptions(
    chartData: IDataRowCollection,
    chartConfig: IChartConfig,
    currentOptions: Highcharts.Options,
    pageTitle: string,
    serieVisibilitySubject: Subject<ISerieVisibility>,
    selectedChartType: ChartOptionType,
    viewType: string,
    serieOptions: IDataRowCollection,
    xAxisOptions: IDataRowCollection,
    instellingGroepValue: string,
    appTranslations: any
  ): Highcharts.Options {
    const chartSeriesService = new ChartSeriesService();
    const chartAxisService = new ChartAxisService();
    const chartEventsService = new ChartEventsService();
    const chartTooltipService = new ChartTooltipService();
    const chartExportService = new ChartExportService();

    const chartOptions: Highcharts.Options = cloneDeep(currentOptions);
    const mappedDataRows = chartData
      .sort((itemA: any, itemB: any) => {
        const xAxisValueA =
          itemA.dataRowMappedFilters[chartConfig.xAxisSelectionFilter];
        const xAxisValueB =
          itemB.dataRowMappedFilters[chartConfig.xAxisSelectionFilter];

        if (xAxisValueA < xAxisValueB) {
          return -1;
        } else if (xAxisValueA > xAxisValueB) {
          return 1;
        }
        return 0;
      })
      .sort((itemA: any, itemB: any) => {
        const serieValueA =
          itemA.dataRowMappedFilters[chartConfig.seriesSelectionFilter];
        const serieValueB =
          itemB.dataRowMappedFilters[chartConfig.seriesSelectionFilter];
        if (serieValueA < serieValueB) {
          return -1;
        } else if (serieValueA > serieValueB) {
          return 1;
        }
        return 0;
      });

    // Set Default Highcharts settings
    chartOptions.colors = clone(colors);
    chartOptions.legend.enabled = chartConfig.showLegend;

    // Set Axis Values
    chartOptions.xAxis = chartAxisService.getXAxisValues(
      chartConfig,
      viewType,
      instellingGroepValue,
      mappedDataRows,
      xAxisOptions
    );
    chartOptions.yAxis = chartAxisService.getYAxisValues(
      chartConfig,
      mappedDataRows
    );

    // Set Series Values
    chartOptions.series = chartSeriesService.getChartSeries(
      chartConfig,
      mappedDataRows,
      serieOptions,
      chartOptions.xAxis.categories,
      viewType
    );

    // Set Title Value
    chartOptions.chart.marginTop = 75;
    chartOptions.title = {
      text: chartConfig.chartTitle,
      align: 'left',
      x: 5,
      y: 25,
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: Color.DarkBlue,
      },
    };

    // Set Tooltip Value
    chartOptions.tooltip = chartTooltipService.getTooltipValue(
      chartOptions,
      chartConfig,
      mappedDataRows,
      appTranslations,
      viewType
    );

    // Set Chart events
    chartOptions.plotOptions.series.events =
      chartEventsService.getChartEventsValues(serieVisibilitySubject);
    if (selectedChartType === 'stacked') {
      chartOptions.plotOptions.column = {
        stacking: 'normal',
      };
    } else {
      chartOptions.plotOptions.column = {
        stacking: undefined,
      };
    }

    // Set Chart Export
    chartOptions.lang = chartExportService.getChartLang(appTranslations);
    chartOptions.exporting = chartExportService.getChartExporting(pageTitle);

    return chartOptions;
  }
}
