import { Color } from '@nkr-online/interfaces';
export const colors = [
  Color.LightBlue,
  Color.DarkBlue,
  Color.Green,
  Color.Orange,
  Color.Purple,
  Color.Magenta,
  Color.Black,
  Color.LightBlue1,
  Color.DarkBlue1,
  Color.Green1,
  Color.Orange1,
  Color.Purple1,
  Color.Magenta1,
  Color.Black1,
];
