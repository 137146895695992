import { ISelectionFilterConfigCollection } from '../interfaces/filter';

export const filterNamePrefix = '';

export const SelectionFilterNames = {
  Instellinggroep: `DimInstellingInstellingGroep[InstellingGroep]`,
  Instelling: `DimInstelling[HuidigInstellingOmschrijving]`,
  InstellingShort: `DimInstelling[HuidigInstellingAfkorting]`,
  RatioGroepering: `DimRatioSpecifiekeGroepering[RatioSpecifiekeGroepering]`,
  Tumorhoofdgroep: `DimTumorsoort[DefaultTumorsoortHoofdgroepOmschrijving]`,
  Tumorsubgroep: `DimTumorsoort[DefaultTumorsoortSubgroepOmschrijving]`,
  Tumorsoort: `DimTumorsoort[TumorsoortOmschrijving]`,
  VoorbewerkteAnalyse: `DimRatio[RatioTerm]`,
  RatioTeller: 'DimRatio[RatioTeller]',
  RatioTellerNL: `DimRatio[NLRatioTeller]`,
  Jaar: `DimRatioDatum[RatioJaar]`,
  Kwartaal: `DimRatioDatum[RatioKwartaal]`,
  AutorisatieModus: `Z - AutorisatieModus[PortalAutorisatieModus]`,
  Stadium: `DimGeaggregeerdStadium[GeaggregeerdStadiumPresentatieHoofdgroepCode]`,
  Leeftijdcategorie: `DimIncidentieLeeftijd[DefaultLeeftijdCategorieOmschrijving]`,
  Geslacht: `DimGeslacht[GeslachtOmschrijving]`,
  LibraryExportTumorType: `LibraryExportTumorType`,
  LibraryExportHospitalType: `LibraryExportHospitalType`,
};

export const SelectionFilterPrefixedNames = {
  Instellinggroep: `${filterNamePrefix}${SelectionFilterNames.Instellinggroep}`,
  Instelling: `${filterNamePrefix}${SelectionFilterNames.Instelling}`,
  InstellingShort: `${filterNamePrefix}${SelectionFilterNames.InstellingShort}`,
  RatioGroepering: `${filterNamePrefix}${SelectionFilterNames.RatioGroepering}`,
  Tumorhoofdgroep: `${filterNamePrefix}${SelectionFilterNames.Tumorhoofdgroep}`,
  Tumorsubgroep: `${filterNamePrefix}${SelectionFilterNames.Tumorsubgroep}`,
  Tumorsoort: `${filterNamePrefix}${SelectionFilterNames.Tumorsoort}`,
  VoorbewerkteAnalyse: `${filterNamePrefix}${SelectionFilterNames.VoorbewerkteAnalyse}`,
  RatioTeller: `${filterNamePrefix}${SelectionFilterNames.RatioTeller}`,
  RatioTellerNL: `${filterNamePrefix}${SelectionFilterNames.RatioTellerNL}`,
  Jaar: `${filterNamePrefix}${SelectionFilterNames.Jaar}`,
  Kwartaal: `${filterNamePrefix}${SelectionFilterNames.Kwartaal}`,
  AutorisatieModus: `${filterNamePrefix}${SelectionFilterNames.AutorisatieModus}`,
  Stadium: `${filterNamePrefix}${SelectionFilterNames.Stadium}`,
  Leeftijdcategorie: `${filterNamePrefix}${SelectionFilterNames.Leeftijdcategorie}`,
  Geslacht: `${filterNamePrefix}${SelectionFilterNames.Geslacht}`,
  LibraryExportTumorType: `${filterNamePrefix}${SelectionFilterNames.LibraryExportTumorType}`,
  LibraryExportHospitalType: `${filterNamePrefix}${SelectionFilterNames.LibraryExportHospitalType}`,
};

export class BaseConfig {
  public defaultSerieSelectionFilter =
    SelectionFilterPrefixedNames.RatioGroepering;

  public filterMenuConfig: ISelectionFilterConfigCollection = [
    {
      orderNr: 1,
      filterName: SelectionFilterPrefixedNames.Tumorhoofdgroep,
      cssClass: 'col-12 col-md-3',
      hasTotal: true,
      totalValueLabel: 'Alle Tumorhoofdgroepen',
      multiple: false,
      infoButton: {
        enabled: false,
      },
    },
    {
      orderNr: 2,
      filterName: SelectionFilterPrefixedNames.Instellinggroep,
      cssClass: 'col-12 col-md-3',
      hasTotal: false,
      totalValueLabel: null,
      multiple: false,
      infoButton: {
        enabled: true,
        tab: 'instellingen',
      },
    },
    {
      orderNr: 3,
      filterName: SelectionFilterPrefixedNames.VoorbewerkteAnalyse,
      cssClass: 'col-12 col-md-6',
      hasTotal: false,
      totalValueLabel: null,
      multiple: false,
      infoButton: {
        enabled: true,
        tab: 'definitie',
      },
    },
    {
      orderNr: 4,
      filterName: SelectionFilterPrefixedNames.LibraryExportTumorType,
      cssClass: 'col-12 col-md-6',
      hasTotal: false,
      totalValueLabel: null,
      multiple: false,
      infoButton: {
        enabled: false,
      },
    },
    {
      orderNr: 5,
      filterName: SelectionFilterPrefixedNames.LibraryExportHospitalType,
      cssClass: 'col-12 col-md-6',
      hasTotal: false,
      totalValueLabel: null,
      multiple: false,
      infoButton: {
        enabled: false,
      },
    },
  ];

  public subsetMenuConfig: ISelectionFilterConfigCollection = [
    {
      orderNr: 1,
      filterName: SelectionFilterPrefixedNames.Jaar,
      cssClass: 'col-12',
      hasTotal: true,
      totalValueLabel: 'Alle Jaren',
      multiple: true,
      infoButton: {
        enabled: false,
      },
    },
    {
      orderNr: 2,
      filterName: SelectionFilterPrefixedNames.Tumorsubgroep,
      cssClass: 'col-12',
      hasTotal: true,
      totalValueLabel: 'Alle Tumorsubgroepen',
      multiple: true,
      infoButton: {
        enabled: false,
      },
    },
    {
      orderNr: 3,
      filterName: SelectionFilterPrefixedNames.Stadium,
      cssClass: 'col-12',
      hasTotal: true,
      totalValueLabel: 'Alle Stadia',
      multiple: true,
      infoButton: {
        enabled: false,
      },
    },
    {
      orderNr: 4,
      filterName: SelectionFilterPrefixedNames.Leeftijdcategorie,
      cssClass: 'col-12',
      hasTotal: true,
      totalValueLabel: 'Alle Leeftijdsgroepen',
      multiple: true,
      infoButton: {
        enabled: false,
      },
    },
    {
      orderNr: 5,
      filterName: SelectionFilterPrefixedNames.Geslacht,
      cssClass: 'col-12',
      hasTotal: true,
      totalValueLabel: 'Alle Geslachten',
      multiple: false,
      infoButton: {
        enabled: false,
      },
    },
  ];

  public authorizationMenuConfig: ISelectionFilterConfigCollection = [
    {
      orderNr: 1,
      filterName: SelectionFilterPrefixedNames.AutorisatieModus,
      cssClass: 'col-12',
      hasTotal: false,
      totalValueLabel: null,
      multiple: false,
      infoButton: {
        enabled: false,
      },
    },
    {
      orderNr: 2,
      filterName: SelectionFilterPrefixedNames.Instelling,
      cssClass: 'col-12',
      hasTotal: false,
      totalValueLabel: 'Alle Ziekenhuizen',
      multiple: false,
      infoButton: {
        enabled: false,
      },
    },
  ];
}
