import { IRepo } from './repo.interface';
import { IRepoDto } from './repo.dto.interface';

export class RepoMapper {
  public toDomain(): IRepo {
    return;
  }

  public toDto(): IRepoDto {
    return;
  }
}
