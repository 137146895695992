export enum Color {
  LightBlue = '#11B5E9',
  DarkBlue = '#006d8c',
  Green = '#A5D867',
  Orange = '#EC7A08',
  Purple = '#A47CC9',
  Magenta = '#C30072',
  Black = '#767676',
  LightBlue1 = '#88DAF4',
  DarkBlue1 = '#66A7BA',
  Green1 = '#C5E69C',
  Orange1 = '#F3A95F',
  Purple1 = '#C0A4D9',
  Magenta1 = '#E266AB',
  Black1 = '#BABABA',
}
