export interface IDataRowMeta {
  numerator?: string;
  denominator?: string;
  hasDenominatorCutoff?: boolean;
  unit?: 'Percentage' | 'Aantal';
}

export interface IDataRowFilterItem {
  filterName: string;
  filterValue: string;
}
export type IDataRowFilterItemCollection = IDataRowFilterItem[];

export interface IDataRow {
  dataRowFilters: IDataRowFilterItemCollection;
  dataRowMappedFilters: {
    [key: string]: string;
  };
  meta?: IDataRowMeta;
  value: number;
}
export type IDataRowCollection = IDataRow[];

export class DataRowMeta implements IDataRowMeta {
  public numerator;
  public denominator;
  public hasDenominatorCutoff;
  public unit;
  constructor(input: IDataRowMeta) {
    this.numerator = input.numerator;
    this.denominator = input.denominator;
    this.hasDenominatorCutoff = input.hasDenominatorCutoff;
    this.unit = input.unit;
  }
}

export class DataRow implements IDataRow {
  public dataRowFilters;
  public dataRowMappedFilters;
  public meta;
  public value;
  constructor(input: IDataRow) {
    this.dataRowMappedFilters = input.dataRowMappedFilters;
    this.dataRowFilters = input.dataRowFilters;
    this.meta = new DataRowMeta(input.meta);
    this.value = input.value;
  }
}
