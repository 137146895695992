<div
  class="iknl-new-features-popup"
  [ngClass]="{
    'iknl-new-features-popup': true,
    'iknl-new-features-popup--active': modalVisible
  }"
>
  <div class="iknl-new-features-popup__wrapper">
    <div class="iknl-new-features-popup__backdrop"></div>
    <div class="iknl-new-features-popup__content">
      <div class="iknl-new-features-popup__content-header">
        <div class="iknl-new-features-popup__content-header-inner">
          <h2 class="iknl-new-features-popup__content-header-title">
            {{ 'components.newFeatures.title' | translate }}
          </h2>
          <p>
            {{ 'components.newFeatures.description' | translate }}
          </p>
        </div>
        <div class="iknl-new-features-popup__right">
          <div class="iknl-new-features-popup__close" (click)="closeModal()">
            {{ 'components.newFeatures.close' | translate }}
          </div>
          <div class="iknl-new-features-popup__checkbox">
            <input
              type="checkbox"
              id="preventFutureCheckbox"
              name="preventFutureCheckbox"
              [(ngModel)]="preventInFuture"
              checked
            />
            <label for="preventFutureCheckbox">{{
              'components.newFeatures.preventFutureText' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="iknl-new-features-popup__content-body">
        <markdown *ngIf="releaseNotesUrl" [src]="releaseNotesUrl"></markdown>
      </div>
    </div>
  </div>
</div>
