import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'iknl-new-features-popup',
  templateUrl: './new-features-popup.component.html',
  styleUrls: ['./new-features-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewFeaturesPopupComponent implements OnInit {
  @Input() releaseNotesUrl: string;
  preventInFuture = false;
  modalVisible = true;

  constructor() {}

  ngOnInit(): void {
    const localStoragePreventValue = sessionStorage.getItem(
      'preventFeaturePopup'
    );
    if (localStoragePreventValue && localStoragePreventValue === 'true') {
      this.modalVisible = false;
    }
  }

  public closeModal() {
    this.modalVisible = false;
    if (this.preventInFuture && this.preventInFuture === true) {
      sessionStorage.setItem('preventFeaturePopup', 'true');
    }
  }
}
