import { NgModule, Injectable } from '@angular/core';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthGuard, LandingAuthGuard } from './auth-guard.service';
import { AuthLoginService } from './auth-login.service';
import { AuthService } from './auth.service';

@Injectable()
export class IknlAuthConfig {
  public issuer: string;
  public clientId: string;
  public scope: string;
  public responseType: string;
  public useSilentRefresh: boolean;
  public redirectUri?: string;
}

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [OAuthModule.forRoot()],
  providers: [
    AuthService,
    AuthLoginService,
    AuthGuard,
    LandingAuthGuard,
    OAuthService,
  ],
})
export class AuthModule {}
