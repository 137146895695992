import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import {
  ChartComponent,
  InformationModalComponent,
  LoaderComponent,
  PageHeaderComponent,
  PageLayoutComponent,
  SelectionFilterBoxComponent,
  SelectionFilterMenuComponent,
  UserInfoComponent,
  ViewTabsComponent,
} from '@nkr-online/components';
import { HighchartsChartModule } from 'highcharts-angular';
import { InlineSVGModule } from 'ng-inline-svg';
import { MarkdownModule } from 'ngx-markdown';
import { FeedbackComponent } from './feedback/feedback.component';
import { NewFeaturesPopupComponent } from './new-features-popup/new-features-popup.component';

@NgModule({
  declarations: [
    PageLayoutComponent,
    SelectionFilterBoxComponent,
    PageHeaderComponent,
    LoaderComponent,
    ViewTabsComponent,
    ChartComponent,
    SelectionFilterMenuComponent,
    InformationModalComponent,
    UserInfoComponent,
    FeedbackComponent,
    NewFeaturesPopupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    InlineSVGModule,
    HighchartsChartModule,
    TranslateModule.forChild(),
    MarkdownModule.forRoot(),
  ],
  providers: [],
  exports: [
    PageLayoutComponent,
    SelectionFilterBoxComponent,
    PageHeaderComponent,
    LoaderComponent,
    ViewTabsComponent,
    ChartComponent,
    SelectionFilterMenuComponent,
    InformationModalComponent,
    UserInfoComponent,
    FeedbackComponent,
    NewFeaturesPopupComponent,
  ],
})
export class ComponentsModule {}
