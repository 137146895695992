import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RepoQuery } from '@nkr-online/repo';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'iknl-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  @Input() public userInfo: {
    name: string;
    email: string;
  };

  @Input() public feedbackTranslations: any;

  public feedbackFormOpened = false;
  public feedbackFormGroup: FormGroup;
  public formMessage: string;

  public isBusy = false;

  constructor(private formBuilder: FormBuilder, private repoQuery: RepoQuery) {
    this.feedbackFormGroup = this.formBuilder.group({
      userFullName: ['', Validators.required],
      userEmail: ['', Validators.required],
      feedback: ['', Validators.required],
      allowContact: [false],
    });
  }

  public ngOnInit() {
    if (this.userInfo) {
      this.feedbackFormGroup.get('userEmail').setValue(this.userInfo.email);
    }
  }

  public submitFeedbackHandler() {
    if (this.feedbackFormGroup.invalid) {
      this.formMessage = this.feedbackTranslations.messages.invalid;
    } else {
      this.isBusy = true;
      const currentFormValue = cloneDeep(this.feedbackFormGroup.value);

      if (
        currentFormValue.allowContact === null ||
        currentFormValue.allowContact === false
      ) {
        currentFormValue.allowContact = this.feedbackTranslations.no;
      } else {
        currentFormValue.allowContact = this.feedbackTranslations.yes;
      }

      const subscriber = this.repoQuery
        .sendFeedbackResponse(currentFormValue)
        .subscribe(
          () => {
            this.formMessage = this.feedbackTranslations.messages.success;
            setTimeout(() => {
              this.isBusy = false;
              this.toggleFeedbackForm();
            }, 2500);
            subscriber.unsubscribe();
          },
          (err: any) => {
            this.isBusy = false;
            this.formMessage = this.feedbackTranslations.messages.error;
            console.error(err);
            subscriber.unsubscribe();
          }
        );
    }
  }

  public toggleFeedbackForm() {
    this.feedbackFormOpened = !this.feedbackFormOpened;
    if (this.feedbackFormOpened === true) {
      this.feedbackFormGroup.get('feedback').reset();
      this.feedbackFormGroup.get('allowContact').reset();
      this.formMessage = null;
    }
  }
}
