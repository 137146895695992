import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isDoneLoading$.pipe(
      filter((isDone) => isDone),
      switchMap((_) => this.authService.isAuthenticated$),
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          const landingRoute = _route.data.landingRoute as string;
          this.router.navigate(['/' + landingRoute]);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}

@Injectable()
export class LandingAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isDoneLoading$.pipe(
      filter((isDone) => isDone),
      switchMap((_) => this.authService.isAuthenticated$),
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          return true;
        } else {
          const initialRoute = _route.data.initialRoute as string;
          this.router.navigate(['/' + initialRoute]);
          return false;
        }
      })
    );
  }
}
