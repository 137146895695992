import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { getAuthConfig } from './auth-config';
import { AuthMessages } from './auth-messages';

type MessageType = 'Info' | 'Error' | 'Warning' | 'Danger';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private authErrorSubject$ = new ReplaySubject<{
    code: string;
    message: string;
    messageType: MessageType;
  }>();
  public authError$ = this.authErrorSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values) => values.every((b) => b)));

  public set isDoneLoading(value: boolean) {
    this.isDoneLoadingSubject$.next(value);
  }

  public get accessToken() {
    return this.oauthService.getAccessToken();
  }
  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }
  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }
  public get idToken() {
    return this.oauthService.getIdToken();
  }
  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }

  private getMessageType(messageCode: string): MessageType {
    switch (messageCode) {
      case AuthMessages.LoginRequired:
        return 'Info';
      default:
        return 'Warning';
    }
  }

  constructor(private oauthService: OAuthService, private router: Router) {
    // Useful for debugging:
    this.oauthService.events.subscribe((eventRes: OAuthEvent) => {
      if (eventRes instanceof OAuthErrorEvent) {
        const errorItemEvent: OAuthErrorEvent = eventRes;
        if (errorItemEvent && errorItemEvent.params) {
          const messageItem = {
            code: errorItemEvent.params['error'],
            message: errorItemEvent.params['error_description']
              ?.split('+')
              ?.join(' '),
            messageType: this.getMessageType(errorItemEvent.params['error']),
          };

          this.authErrorSubject$.next(messageItem);
        }
        console.error(errorItemEvent);
      }
      // else {
      //     console.warn(eventRes)
      // }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn(
        'Noticed changes to access_token (most likely from another tab), updating isAuthenticated'
      );
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );
    });

    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );
    });

    // if (this.oauthService.useSilentRefresh === true) {
    this.oauthService.setupAutomaticSilentRefresh();
    // }
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    if (targetUrl) {
      this.oauthService.initLoginFlow(targetUrl || this.router.url);
    } else {
      this.oauthService.initLoginFlow();
    }
  }

  public updateAuthConfig(
    clientId: string,
    scope: string,
    issuer: string,
    responseType: string,
    landingEndpoint: string,
    useSilentRefresh: boolean
  ) {
    // this.oauthService.issuer = config.issuer
    // this.oauthService.clientId = config.clientId
    // this.oauthService.responseType = config.responseType
    // this.oauthService.scope = config.scope

    this.oauthService.configure(
      getAuthConfig(
        clientId,
        scope,
        issuer,
        responseType,
        useSilentRefresh,
        landingEndpoint
      )
    );
  }

  public logout() {
    this.oauthService.logOut();
  }

  public refresh() {
    this.oauthService.silentRefresh();
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  public get userInfo(): {
    email: string;
    userId: string;
  } {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }

    return {
      email: claims.preferred_username,
      userId: claims.oid,
    };
  }
}
