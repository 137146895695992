import { IFilterValueItemCollection } from '../selection-filter-value/selection-filter-value.interface';
import { IRepo } from '../repo.interface';

/**
 * Domain Interfaces
 */
export interface ISelectionFilter extends IRepo {
  filterName: string;
  filterLabel: string;
  isValid: boolean;
  possibleFilterValues?: IFilterValueItemCollection;
  selectedFilterValues?: IFilterValueItemCollection;
}
export type ISelectionFilterCollection = ISelectionFilter[];

export class SelectionFilter implements ISelectionFilter {
  public filterName;
  public filterLabel;
  public isValid;
  public possibleFilterValues;
  public userSelectedFilterValues: any;
  public selectedFilterValues;
  constructor(input: ISelectionFilter) {
    this.filterName = input.filterName;
    this.filterLabel = input.filterLabel;
    this.isValid = input.isValid;
    this.possibleFilterValues = input.possibleFilterValues;
    this.selectedFilterValues = input.selectedFilterValues;
  }
}
