<div class="iknl-page">
  <main
    [ngClass]="{
      'iknl-page__main': true,
      'iknl-page__main--toggled': view,
      'iknl-page__main--sidebar': layout === 'sidebar'
    }"
    #mainContainer
  >
    <div
      [ngClass]="{
        'iknl-page__backdrop': true,
        'iknl-page__backdrop--visible': view
      }"
      (click)="toggleDisplay()"
    ></div>
    <iknl-view-tabs
      [activeLink]="activeLink"
      [view]="view"
      [showToggler]="layout === 'sidebar'"
      (viewChange)="toggleDisplay()"
      [links]="links"
      (routeChange)="routeChangeHandler($event)"
    ></iknl-view-tabs>
    <div class="iknl-page__container iknl-page__container--main">
      <ng-content select="[main]"></ng-content>
    </div>
  </main>

  <aside
    *ngIf="layout === 'sidebar'"
    [ngClass]="{
      'iknl-page__sidebar': true,
      'iknl-page__sidebar--toggled': view
    }"
  >
    <div class="iknl-page__container iknl-page__container--sidebar">
      <ng-content select="[sidebar]"></ng-content>
    </div>
  </aside>

  <div
    class="iknl-page__fullscreen"
    *ngIf="isLoading || (error && error.hasError)"
  >
    <div class="iknl-page__error" *ngIf="error && error.hasError">
      <div
        class="iknl-page__error-icon"
        aria-label="Warning Icon"
        [inlineSVG]="'assets/icons/warning.svg'"
      ></div>
      <ng-container *ngIf="error.type === 500">
        <h2 class="iknl-page__error-title">
          {{ 'components.pageLayout.error500Title' | translate }}
        </h2>
        <p class="iknl-page__error-text">
          {{ 'components.pageLayout.error500Text' | translate }}
        </p>
      </ng-container>

      <ng-container *ngIf="error.type === 404">
        <h2 class="iknl-page__error-title">
          {{ 'components.pageLayout.error404Title' | translate }}
        </h2>
        <p class="iknl-page__error-text">
          {{ 'components.pageLayout.error404Text' | translate }}
        </p>
      </ng-container>
      <div
        (click)="
          routeChangeHandler({
            href: '',
            label: '',
            eventType: 'logout'
          })
        "
        title="Opnieuw Inloggen"
        class="iknl-page__error-button"
      >
        {{ 'components.pageLayout.error404Button' | translate }}
      </div>

      <!-- <div
                class="iknl-page__button"
                (click)=""
            >
                Ga naar startpagina
            </div> -->
    </div>

    <iknl-loader class="iknl-page__loader" *ngIf="isLoading"></iknl-loader>
  </div>
</div>
