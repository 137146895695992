import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/app-config.service';
import { RepoQuery } from '../repo.query';
import {
  ISelectionFilterDto,
  ISelectionFilterDtoCollection,
} from './selection-filter.dto.interface';
import {
  ISelectionFilter,
  ISelectionFilterCollection,
} from './selection-filter.interface';
import { SelectionFilterMapper } from './selection-filter.mapper';
import {
  AuthorizationFiltersMenuListResponse,
  SelectionFilterMenuListRequest,
  SelectionFilterMenuListResponse,
} from './selection-filter.request.interface';

@Injectable()
export class SelectionFilterQuery extends RepoQuery {
  constructor(protected http: HttpClient, appConfig: AppConfigService) {
    super(http, appConfig);
  }

  public getSelectionDetails(
    filters: ISelectionFilterCollection,
    authorizationFilters: ISelectionFilterCollection,
    params?: any
  ): Observable<AuthorizationFiltersMenuListResponse> {
    return this.http.post<AuthorizationFiltersMenuListResponse>(
      `${this.apiUrl}/selectionDetails`,
      {
        filters: filters.map((filter: ISelectionFilter) => {
          return SelectionFilterMapper.requestToDto(filter);
        }),
        authorizationFilters: authorizationFilters.map(
          (filter: ISelectionFilter) => {
            return SelectionFilterMapper.requestToDto(filter);
          }
        ),
      },
      {
        headers: this.httpOptions.headers,
        params: {
          ...params,
        },
      }
    );
  }

  public getAuthorizationFilters(
    params?: any
  ): Observable<ISelectionFilterCollection> {
    return this.http
      .get<SelectionFilterMenuListResponse>(
        `${this.apiUrl}/authorizationFilters`,
        {
          headers: this.httpOptions.headers,
          params: {
            ...params,
          },
        }
      )
      .pipe(
        map((filterResponse: SelectionFilterMenuListResponse) => {
          const returnedSelectionFilters: ISelectionFilterDtoCollection =
            filterResponse.filters;
          return returnedSelectionFilters.map((filter: ISelectionFilterDto) => {
            return SelectionFilterMapper.responseToDomain(filter);
          });
        })
      );
  }

  public getTrendsMenu(
    userSelectedFilterName: string,
    filters: ISelectionFilterCollection,
    authorizationFilters: ISelectionFilterCollection,
    isInitialRequest: boolean,
    params?: any
  ): Observable<ISelectionFilterCollection> {
    const body: SelectionFilterMenuListRequest = {
      userSelectedFilterName,
      isInitialRequest,
      filters: filters
        .filter((filter: ISelectionFilter) => {
          return (
            filter.filterName !==
              'Z - AutorisatieModus[PortalAutorisatieModus]' &&
            filter.filterName !== 'DimInstelling[HuidigInstellingOmschrijving]'
          );
        })
        .map((filter: ISelectionFilter) => {
          return SelectionFilterMapper.requestToDto(filter);
        }),
      authorizationFilters: authorizationFilters.map(
        (filter: ISelectionFilter) => {
          return SelectionFilterMapper.requestToDto(filter);
        }
      ),
    };

    return this.http
      .post<SelectionFilterMenuListResponse>(
        `${this.apiUrl}/trendsMenu`,
        body,
        {
          headers: this.httpOptions.headers,
          params: {
            ...params,
          },
        }
      )
      .pipe(
        map((filterResponse: SelectionFilterMenuListResponse) => {
          const returnedSelectionFilters: ISelectionFilterDtoCollection =
            filterResponse.filters;
          return returnedSelectionFilters
            .filter((filter: ISelectionFilterDto) => {
              return (
                filter.filterName !==
                  'Z - AutorisatieModus[PortalAutorisatieModus]' &&
                filter.filterName !==
                  'DimInstelling[HuidigInstellingOmschrijving]'
              );
            })
            .map((filter: ISelectionFilterDto) => {
              return SelectionFilterMapper.responseToDomain(filter);
            });
        })
      );
  }

  public getBenchmarkMenu(
    userSelectedFilterName: string,
    filters: ISelectionFilterCollection,
    authorizationFilters: ISelectionFilterCollection,
    isInitialRequest: boolean,
    params?: any
  ): Observable<ISelectionFilterCollection> {
    const body: SelectionFilterMenuListRequest = {
      userSelectedFilterName,
      isInitialRequest,
      filters: filters
        .filter((filter: ISelectionFilter) => {
          return (
            filter.filterName !==
              'Z - AutorisatieModus[PortalAutorisatieModus]' &&
            filter.filterName !== 'DimInstelling[HuidigInstellingOmschrijving]'
          );
        })
        .map((filter: ISelectionFilter) => {
          return SelectionFilterMapper.requestToDto(filter);
        }),
      authorizationFilters: authorizationFilters.map(
        (filter: ISelectionFilter) => {
          return SelectionFilterMapper.requestToDto(filter);
        }
      ),
    };

    return this.http
      .post<SelectionFilterMenuListResponse>(
        `${this.apiUrl}/benchmarkMenu`,
        body,
        {
          headers: this.httpOptions.headers,
          params: {
            ...params,
          },
        }
      )
      .pipe(
        map((filterResponse: SelectionFilterMenuListResponse) => {
          const returnedSelectionFilters: ISelectionFilterDtoCollection =
            filterResponse.filters;

          return returnedSelectionFilters
            .filter((filter: ISelectionFilterDto) => {
              return (
                filter.filterName !==
                  'Z - AutorisatieModus[PortalAutorisatieModus]' &&
                filter.filterName !==
                  'DimInstelling[HuidigInstellingOmschrijving]'
              );
            })
            .map((filter: ISelectionFilterDto) => {
              return SelectionFilterMapper.responseToDomain(filter);
            });
        })
      );
  }

  public getLibraryMenu(
    userSelectedFilterName: string,
    filters: ISelectionFilterCollection,
    authorizationFilters: ISelectionFilterCollection,
    isInitialRequest: boolean,
    params?: any
  ): Observable<ISelectionFilterCollection> {
    const body: SelectionFilterMenuListRequest = {
      userSelectedFilterName,
      isInitialRequest,
      filters: filters
        .filter((filter: ISelectionFilter) => {
          return (
            filter.filterName !==
              'Z - AutorisatieModus[PortalAutorisatieModus]' &&
            filter.filterName !== 'DimInstelling[HuidigInstellingOmschrijving]'
          );
        })
        .map((filter: ISelectionFilter) => {
          return SelectionFilterMapper.requestToDto(filter);
        }),
      authorizationFilters: authorizationFilters.map(
        (filter: ISelectionFilter) => {
          return SelectionFilterMapper.requestToDto(filter);
        }
      ),
    };

    return this.http
      .post<SelectionFilterMenuListResponse>(
        `${this.apiUrl}/libraryMenu`,
        body,
        {
          headers: this.httpOptions.headers,
          params: {
            ...params,
          },
        }
      )
      .pipe(
        map((filterResponse: SelectionFilterMenuListResponse) => {
          const returnedSelectionFilters: ISelectionFilterDtoCollection =
            filterResponse.filters;
          return returnedSelectionFilters.map((filter: ISelectionFilterDto) => {
            return SelectionFilterMapper.responseToDomain(filter);
          });
        })
      );
  }
}
