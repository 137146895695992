import { HttpHeaders } from '@angular/common/http';

export interface IRepoConfig {
  apiUrl: string;
  feedbackUrl: string;
  httpOptions: {
    httpHeaders: HttpHeaders;
    [key: string]: any;
  };
}

export class RepoConfig implements IRepoConfig {
  public apiUrl;
  public feedbackUrl;
  public httpOptions: any;
  constructor(apiUrl: string, feedbackUrl: string) {
    this.apiUrl = apiUrl;
    this.feedbackUrl = feedbackUrl;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }
}
