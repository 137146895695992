<div
  [ngClass]="{
    'iknl-view-tabs': true,
    'iknl-view-tabs--toggled': view,
    'iknl-view-tabs--fullscreen': !showToggler
  }"
>
  <ul
    [ngClass]="{
      'iknl-view-tabs__wrapper': true,
      'iknl-view-tabs__wrapper--visible': menuVisible
    }"
  >
    <li class="iknl-view-tabs__item" *ngFor="let link of links">
      <a
        *ngIf="link.eventType === 'externalRoute'"
        href="{{ link.href }}"
        class="iknl-view-tabs__item-link"
        target="_blank"
        title="{{ link.label }}"
        >{{ link.label }}</a
      >
      <a
        *ngIf="link.eventType !== 'externalRoute'"
        (click)="onRouteLinkClicked(link)"
        [ngClass]="{
          'iknl-view-tabs__item-link': true,
          'iknl-view-tabs__item-link--active':
            link.href.indexOf(activeLink) !== -1
        }"
        title="{{ link.label }}"
        >{{ link.label }}</a
      >
    </li>
  </ul>

  <div
    [ngClass]="{
      'iknl-view-tabs__item': true,
      'iknl-view-tabs__item--menu': true
    }"
  >
    <div class="iknl-view-tabs__item-link" (click)="toggleMenu()">
      <div
        class="iknl-view-tabs__item-icon"
        aria-label="Menu Icon"
        [inlineSVG]="'assets/icons/menu.svg'"
      ></div>
    </div>
  </div>

  <div
    *ngIf="showToggler"
    [ngClass]="{
      'iknl-view-tabs__item': true,
      'iknl-view-tabs__item--filter': true,
      'iknl-view-tabs__item--toggled': view
    }"
  >
    <div class="iknl-view-tabs__item-link" (click)="toggleDisplay()">
      <div
        class="iknl-view-tabs__item-icon"
        aria-label="Filter Icon"
        [inlineSVG]="'assets/icons/filter.svg'"
      ></div>
    </div>
  </div>
</div>
