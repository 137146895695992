import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InsightsService } from '@nkr-online/services';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './services/app-config.service';
import { AuthModule, IknlAuthConfig } from './services/auth/_index';
import { SharedModule } from './shared.module';

const appInitializerFn = (
  appConfig: AppConfigService,
  appInsights: InsightsService
) => {
  return (): Promise<any> => {
    return new Promise(async (resolve) => {
      await appConfig.loadAppConfig();
      const appConfiguration = appConfig.getConfig();
      appInsights.init(appConfiguration);
      resolve(true);
    });
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AuthModule,
    AppRoutingModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    AppConfigService,
    InsightsService,
    IknlAuthConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService, InsightsService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
