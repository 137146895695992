import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  OnInit,
  ViewEncapsulation,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { INavLink, INavLinkCollection } from '@nkr-online/interfaces';

@Component({
  selector: 'iknl-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageLayoutComponent implements OnChanges, OnInit {
  @Input() public links: INavLinkCollection;
  @Input() public layout: 'fullscreen' | 'sidebar';
  @Input() public isLoading: boolean;
  @Input() public error: {
    type: 404 | 500;
    hasError: boolean;
  };
  @Input() public view: boolean;
  @Output() public viewChange: EventEmitter<boolean> = new EventEmitter();
  @Output() public routeChange: EventEmitter<INavLink> = new EventEmitter();

  @ViewChild('mainContainer', { static: true })
  public mainContainer: ElementRef;

  public activeLink = '';

  constructor(private router: Router) {}

  public routeChangeHandler(routeLink: INavLink) {
    this.routeChange.next(routeLink);
  }

  public ngOnInit() {
    this.activeLink = this.router.url;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading && this.isLoading === false) {
      this.mainContainer.nativeElement.scrollTop = 0;
    }
  }

  public toggleDisplay() {
    const newView = !this.view;
    this.viewChange.next(newView);
  }
}
