import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class TranslationResolver implements Resolve<Observable<string>> {
  constructor(private translateService: TranslateService) {}

  public resolve() {
    return this.translateService.get([
      'app',
      'components',
      'pages',
      'services',
    ]);
  }
}
