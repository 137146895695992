export interface IDataRowMetaDto {
  numerator?: string;
  denominator?: string;
  hasDenominatorCutoff?: boolean;
  unit?: 'Percentage' | 'Aantal';
}

export interface IDataRowFilterItemDto {
  filterName: string;
  filterValue: string;
}
export type IDataRowFilterItemDtoCollection = IDataRowFilterItemDto[];

export interface IDataRowDto {
  dataRowFilters: IDataRowFilterItemDtoCollection;
  meta?: IDataRowMetaDto;
  value: number;
}
export type IDataRowDtoCollection = IDataRowDto[];

export class DataRowMetaDto implements IDataRowMetaDto {
  public numerator;
  public denominator;
  public hasDenominatorCutoff;
  public unit;
  constructor(input: IDataRowMetaDto) {
    this.numerator = input.numerator;
    this.denominator = input.denominator;
    this.hasDenominatorCutoff = input.hasDenominatorCutoff;
    this.unit = input.unit;
  }
}

export class DataRowDto implements IDataRowDto {
  public dataRowFilters;
  public meta;
  public value;
  constructor(input: IDataRowDto) {
    this.dataRowFilters = input.dataRowFilters;
    this.meta = new DataRowMetaDto(input.meta);
    this.value = input.value;
  }
}
