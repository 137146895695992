<div class="iknl-user-info">
  <div class="iknl-user-info__icon-wrap">
    <div
      class="iknl-user-info__icon"
      aria-label="User Icon"
      [inlineSVG]="'assets/icons/user.svg'"
    ></div>
  </div>
  <div class="iknl-user-info__content">
    <h2 class="iknl-user-info__name">
      {{ userInfo.name }}
    </h2>
    <h3 class="iknl-user-info__email">{{ userInfo.email }}</h3>
  </div>

  <div class="iknl-user-info__logout-btn" (click)="logout()">Uitloggen</div>
</div>
