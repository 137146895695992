<div
  [ngClass]="{
    'iknl-information-modal': true,
    'iknl-information-modal--active': modalVisible
  }"
>
  <div
    class="iknl-information-modal__backdrop"
    (click)="emitCloseModal()"
  ></div>
  <div class="iknl-information-modal__content">
    <div class="iknl-information-modal__header">
      <div class="iknl-information-modal__tabs" role="tablist">
        <div
          class="iknl-information-modal__tab"
          *ngIf="instellingGroep"
          (click)="emitChangeTab('instellingen')"
          [ngClass]="{
            'iknl-information-modal__tab': true,
            'iknl-information-modal__tab--active':
              selectedTab === 'instellingen'
          }"
        >
          {{
            'components.informationModal.tabInstellingen'
              | translate: { label: instellingGroep }
          }}
        </div>
        <div
          (click)="emitChangeTab('definitie')"
          [ngClass]="{
            'iknl-information-modal__tab': true,
            'iknl-information-modal__tab--active': selectedTab === 'definitie'
          }"
        >
          {{ 'components.informationModal.tabDefinitie' | translate }}
        </div>
      </div>
      <div class="iknl-information-modal__close" (click)="emitCloseModal()">
        {{ 'components.informationModal.close' | translate }}
      </div>
    </div>
    <div class="iknl-information-modal__body">
      <ng-container *ngIf="selectedTab === 'definitie'">
        <p class="iknl-information-modal__text">
          {{ 'components.informationModal.tabDefinitieHeading' | translate }}
        </p>
        <pre class="iknl-information-modal__pre">
                    {{ definitieAnalyse }}
                </pre
        >
      </ng-container>

      <ng-container *ngIf="selectedTab === 'instellingen' && instellingGroep">
        <p class="iknl-information-modal__text">
          {{
            'components.informationModal.tabInstellingenHeading'
              | translate: { label: instellingGroep }
          }}
        </p>
        <ul class="iknl-information-modal__instellingen">
          <li
            class="iknl-information-modal__instellingen-item"
            *ngFor="let instelling of instellingen"
          >
            {{ instelling }}
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
