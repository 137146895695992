import { RepoMapper } from '../repo.mapper';
import {
  IDataRowDto,
  DataRowDto,
  DataRowMetaDto,
} from './datarow.dto.interface';
import { IDataRow, DataRow, DataRowMeta } from './datarow.interface';

export class DataRowMapper extends RepoMapper {
  public static dataResponseToDomain(input: IDataRowDto): IDataRow {
    const dataRowMappedFilters = {};
    input.dataRowFilters.forEach((filter) => {
      dataRowMappedFilters[filter.filterName] = filter.filterValue;
    });
    return new DataRow({
      dataRowFilters: input.dataRowFilters,
      dataRowMappedFilters,
      meta: new DataRowMeta(input.meta),
      value: input.value,
    });
  }

  public static requestToDto(input: IDataRow): IDataRowDto {
    const meta = {
      ...input.meta,
    };
    return new DataRowDto({
      dataRowFilters: input.dataRowFilters,
      meta: new DataRowMetaDto(meta),
      value: input.value,
    });
  }
}
