import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InsightsService {
  private appInsights: ApplicationInsights;

  constructor(private router: Router) {}

  public init(appConfigObj: any) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appConfigObj.instrumentationKey.value,
        enableCorsCorrelation: true,
      },
    });

    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    this.createRouterSubscription();
  }

  // private logException(error: Error) {
  //     let exception: IExceptionTelemetry = {
  //         exception: error,
  //     }
  //     this.appInsights.trackException(exception)
  // }

  public trackEvent(name: string, values?: { [key: string]: any }) {
    this.appInsights.trackEvent(
      {
        name,
      },
      values
    );
  }

  public setUserId(userId: string, userName: string) {
    this.appInsights.setAuthenticatedUserContext(userId, userName, true);
  }

  public clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  public logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  public setHospitalName(hospitalName: string) {
    this.appInsights.addTelemetryInitializer((envelope) => {
      var item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties['HospitalName'] = hospitalName;
    });
  }

  public loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer((envelope) => {
      var item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties['ApplicationPlatform'] = 'Web';
      item.properties['ApplicationName'] = 'NKR Online';
    });
  }

  private createRouterSubscription() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.logPageView(event.urlAfterRedirects, event.urlAfterRedirects);
      });
  }
}
