import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../services/app-config.service';
import { RepoConfig } from './repo.config';

export interface IDatasetReload {
  latestDatasetReloadDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class RepoQuery {
  protected httpOptions;
  protected apiUrl;
  protected feedbackUrl;

  constructor(protected http: HttpClient, appConfig: AppConfigService) {
    const appConfigObj = appConfig.getConfig();
    const repoConfig = new RepoConfig(
      appConfigObj.apiUrl.value,
      appConfigObj.feedbackUrl.value
    );
    this.httpOptions = repoConfig.httpOptions;
    this.apiUrl = repoConfig.apiUrl;
    this.feedbackUrl = repoConfig.feedbackUrl;
  }

  public getDatasetReload(params?: any): Observable<IDatasetReload> {
    return this.http.get<IDatasetReload>(`${this.apiUrl}/datasetReload`, {
      headers: this.httpOptions.headers,
      params: {
        ...params,
      },
    });
  }

  public sendFeedbackResponse(body: any, params?: any): Observable<any> {
    return this.http.post<any>(`${this.feedbackUrl}`, body, {
      ...this.httpOptions,
      params: {
        ...params,
      },
    });
  }

  protected get(params?: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/`, {
      ...this.httpOptions,
      params: {
        ...params,
      },
    });
  }

  protected post(params?: any, body?: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/`, body, {
      ...this.httpOptions,
      params: {
        ...params,
      },
    });
  }

  protected put(params?: any, body?: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/`, body, {
      ...this.httpOptions,
      params: {
        ...params,
      },
    });
  }

  protected delete(params?: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/`, {
      ...this.httpOptions,
      params: {
        ...params,
      },
    });
  }
}
