<div class="iknl-chart" #chartWrapper>
  <highcharts-chart
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    (chartInstance)="updateChartInstance($event)"
    class="iknl-chart__wrapper"
    [ngStyle]="{
      width: '100%',
      height: chartHeight || '300px',
      display: 'block'
    }"
  ></highcharts-chart>
</div>
